import React, { Component } from "react";

class TextField extends Component {
  render() {
    const {
      container,
      label,
      id,
      type,
      placeholder,
      name,
      value,
      required,
      handleChange,
      onBlur,
      onFocus,
      hasError,
      displayError,
      language,
      disabled
    } = this.props;
    let labelClass = "block font-bold mb-2";
    if (required) labelClass += " input-required";
    return (
      <div className={container}>
        <label className={labelClass} htmlFor={id}>
          {label} {required ? <span className="text-danger">*</span> : ""}
        </label>
        <input
          className="appearance-none block w-full border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
          id={id}
          name={name}
          type={type ? type : "text"}
          placeholder={placeholder}
          value={value}
          required={required}
          onChange={handleChange}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
        />
        {hasError && language.includes('en') && <p className="text-danger text-xs italic">{hasError}</p>}
        {hasError && !language.includes('en') && <p className="text-danger text-xs italic">{displayError}</p>}
      </div>
    );
  }
}

export default TextField;
