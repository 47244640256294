import React, { Component } from "react";
import { AppContext } from "../utils/context";
import { formatCurrency } from "../utils/constants";
import AmbassadorInput from "./AmbassadorInput";

class CheckoutSummary extends Component {
  render() {
    const { event, cart, total, envelope, freeTicket, location, cookies } = this.props;
    const { localLanguage, config } = this.context;

    let productForString = config.option_number_model === "single_number_multi_chance" ? localLanguage.general_num_chances_for : localLanguage.general_num_tickets_for;
    let productString = config.option_number_model === "single_number_multi_chance" ? localLanguage.general_num_chances_for : localLanguage.general_num_chances;

    if (window.location.href.includes('payment-info')) return false;
    if (!event.is_active || event.web_sales_paused) return false; // alert user that event ended
    return (
      <div className="w-full lg:w-1/3 lg:float-right sticky pin-t pin-r p-4 z-50 border-primary bg-accent mx-0 checkOutSummary" style={{alignSelf: "flex-start" }}>
        <h4 className="py-2">{localLanguage.general_your_order}</h4>
        {freeTicket ?
          (<div className="flex py-2">
            <div className="flex-2">
              <span className="font-semibold">{localLanguage.general_free_tickets}</span>
            </div>
            <div className="flex-1 text-right">$0</div>
          </div>)
        :
        (cart && cart.map((c, k) => {
          if (c.qty === 0) return false;
          return (
            <div key={k} className="flex py-2">
              <div className="flex-2">
                <p>{c.eventName}</p>
                <span className="orderBreakdown">{`${c.qty} x `}
                  <strong>
                    {c.cost ?
                    `${productForString.replace('{c.tickets}', c.tickets).replace('${c.cost}', formatCurrency(c.cost))}` :
                    `${productString.replace('{c.tickets}', c.tickets)}`
                    }
                  </strong>
                </span>
              </div>
              {c.cost && <div className="flex-1 text-right">{formatCurrency(c.qty * c.cost)}</div>}
            </div>
          );
        }))}
        {event.game.id === 3 && envelope > 0 && (
          <div className="flex py-2">{localLanguage.general_your_env_num} :<span className="flex-1 text-right">{envelope}</span></div>
        )}
        {!freeTicket && total === 0 && (
          <div className="flex py-2">{localLanguage.general_cart_empty}</div>
        )}
        <div className="flex py-2 font-bold border-t">
          <div className="flex-1">{localLanguage.general_total}:</div>
          <div className="flex-1 text-right">{freeTicket ? `${formatCurrency(0)}` : `${formatCurrency(total)}`}</div>
        </div>
        {+event.ambassador_enabled === 1 && location.pathname.indexOf("/thank-you") == -1 && <AmbassadorInput
            event={event}
            label={localLanguage.general_ambassador_referrer_code}
            location={location}
            cookies={cookies}
            disabled={location.pathname.indexOf("/confirm-order") != -1 ? true : false}
        />}
      </div>
    );
  }
}
CheckoutSummary.contextType = AppContext;

export default CheckoutSummary;
