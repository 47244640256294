import React, { Component } from "react";
import { AppContext } from "../utils/context";
import { request as xhr, queryString } from "../utils/request";
import { blankImage } from "../utils/constants";
import Alert from "../components/Alert";
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import Button from "../components/Button";

class PrintDiscountCodeSuper extends Component {
    render() {
        const { localLanguage } = this.context;

        return (
            <div className={`container mx-auto py-6`}>
                <div className="max-w-xs rounded overflow-hidden mb-6 m-auto text-center">
                    <ReactToPrint content={() => this.componentRef}>
                        <PrintContextConsumer>
                            {({ handlePrint }) => (
                                <Button onClick={handlePrint} content={localLanguage.general_print_discount_code_title} />
                            )}
                        </PrintContextConsumer>
                    </ReactToPrint>
                </div>
                <PrintDiscountCode {...this.props} ref={el => (this.componentRef = el)} />
            </div>
        );
    }
}
PrintDiscountCodeSuper.contextType = AppContext;

class PrintDiscountCode extends Component {
    state = {
        discountCode: false,
        errMessage: '',
        text: '',
        img: ''
    }

    componentDidMount = async () => {
        let qs = queryString.parse(this.props.location.search);
        let orderId = qs.orderId ? qs.orderId : qs.orderid;
        let hash = qs.hash ? qs.hash : (qs.orderhash ? qs.orderhash : '');
        xhr.get(`api:/web/order/${orderId}/discount-code`, { 'hash': hash }).then(data => {
            if (data.status === 'error') {
                this.setState({ errMessage: data.error });
            } else {
                this.setState({ discountCode: data.discountCodes });
                this.setState({ img: data.imageUrl ?? null });
                this.setState({ text: data.text ?? null });
            }
        });
    }

    render() {
        const { discountCode, errMessage, img, text } = this.state;
        const { localLanguage } = this.context;

        return (
            <div className={`container mx-auto py-6`}>
                {img && (
                    <div className="text-center mb-4">
                        <img src={img} alt={localLanguage.general_logo} onError={(e) => { e.target.src = blankImage }} className="block mx-auto" />
                    </div>
                )}
                {text && (
                    <div className="text-center mb-4">
                        <h1 className="text-2xl font-bold">{text}</h1>
                    </div>
                )}
                {discountCode && Object.values(discountCode).map((codeGroup, index) => (
                    <div key={index} className="mb-6">
                        <div className="text-center mb-2">
                            <p className="text-lg font-bold">{codeGroup.description}</p>
                        </div>
                        <div className="text-center">
                            {codeGroup.codes.map((codeObj, idx) => (
                                <div key={idx} className="mb-2">
                                    <span className="text-xl font-bold">{codeObj.code}</span>
                                    {codeObj.barcode && (
                                        <div>
                                            <img src={`data:image/png;base64,${codeObj.barcode}`} alt={`Barcode for ${codeObj.code}`} />
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
                {(errMessage && !discountCode) && (
                    <div className="flex flex-wrap mb-6">
                        <div className="w-full md:w-1/2">
                            <Alert
                                visible={false}
                                error={errMessage}
                                success={false}
                                message={errMessage}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
PrintDiscountCode.contextType = AppContext;

export default PrintDiscountCodeSuper;