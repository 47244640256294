import React, { Component } from "react";
import { Link } from "react-router-dom";

import SplitThePot from "./SplitThePot";
import PrizeOnlyDraw from "./PrizeOnlyDraw";
import ChaseTheAce from "./ChaseTheAce";
import CalendarRaffle from "./CalendarRaffle";
import classnames from 'classnames';
import { AppContext } from "../utils/context";

class Hero extends Component {
  handleCheckout = e => {
    e.preventDefault();
    window.location.href = "/select-tickets";
  };

  render() {
    const { event } = this.props;
    const { localLanguage, settings, iFrameAssets } = this.context;

    // Calculate the minimum height based on the number of addons
    const addons = event?.addons || [];
    const baseMinHeight = 560; // base minimum height
    const addonUnitHeight = 70; // height increase per addon

    const calculatedMinHeight = baseMinHeight + addons.length * addonUnitHeight;

    // Update the container's min-height based on the calculated value
    const container = document.getElementById('checkoutContainer');
    if (container) {
        container.style.minHeight = `${calculatedMinHeight}px`;
    }

    const heroBoxClassnames = classnames('w-full', 'text-center', { 'box-it': +settings.hero_box_highlighting === 1 });
    const mobileLogosClassName = classnames('flex', 'items-stretch', 'flex-wrap', 'md:flex-row', 'w-full', 'mobileLogo');
    const heroProceedsTextClasses = classnames('font-semibold', 'text-center', 'p-2', 'heroProceedsText', settings.font_size_proceeds_text ); //text-base

    return (
      <div className="video-main video-bg" style={{ backgroundColor: settings.hero_background_color }}>
        <div className="video cover align-center align-middle">
          {(settings.hero_video && settings.hero_video !== "" || (iFrameAssets && iFrameAssets.heroVideo !== "")) ? (
            <video className="opacity-75 heroVideoClass" src={`${settings.hero_video}`} type="video/mp4" loop muted autoPlay playsInline preload="true" poster={`${settings.hero_video_jpeg !== "" ? settings.hero_video_jpeg : ""}`}>
            </video>
          ) : (
              (settings.hero_image && settings.hero_image !== "" || (iFrameAssets && iFrameAssets.heroImage !== "")) ? (
                <img
                  src={`${settings.hero_image}`}
                  alt=""
                  className="opacity-75 heroImageClass"
                />
              ) : (
                  settings.hero_background_color && settings.hero_background_color !== "" ? (
                    <div style={{ backgroundColor: settings.hero_background_color }} />
                  ) : (
                      <div style={{ backgroundColor: "#FFF !important" }} />
                    )
                )
            )}
        </div>
        <div className="content" id="checkoutContainer">
          <div className="main-hero py-6 absolute w-full max-w-full heroTextColorClass" style={{ color: settings.hero_text_color, zIndex: 1 }}>
            <div className="flex items-stretch flex-wrap md:flex-row w-full">
              <div className="w-full md:w-1/3 primarylogo ">
              <img src={settings.primary_left_logo} alt="" className="block p-6 mx-auto primaryLeftLogoClass" />
              </div>
              <div className="w-full md:w-1/3">
                <div className="flex items-stretch flex-wrap heroTextColorClass" style={{ color: settings.hero_text_color }}>
                  {event.id === 0 ? (
                    <div className="w-full  text-center ">
                      <div className="font-semibold text-xl">{localLanguage.general_events_coming_soon}</div>
                    </div>
                  ) : (
                      <div className={heroBoxClassnames} style={settings.font_family_hero !== "" ? { fontFamily: settings.font_family_hero} : {fontFamily: settings.font_family_hero}} >
                        {event.game.id === 1 && (
                          <SplitThePot
                            event={event}
                            settings={settings}
                          />
                        )}
                        {event.game.id === 2 && (
                          <PrizeOnlyDraw
                            event={event}
                            settings={settings}
                          />
                        )}
                        {event.game.id === 3 && (
                          <ChaseTheAce
                            event={event}
                          />
                        )}
                        {event.game.id === 4 && (
                          <CalendarRaffle
                            event={event}
                            settings={settings}
                          />
                        )}
                        {event.is_active && event.web_enabled && event.websales_active && +event.web_sales_paused !== 1 && +event.shopify_enabled !== 1  && (
                          <Link
                            to={{ pathname: '/select-tickets' }}
                            className="inline-block no-underline bg-accent hover:bg-accent-dark text-primary font-bold text-xl py-4 px-6 rounded mt-4 shadow-outline"
                          >
                            {localLanguage.buy_now_text}
                          </Link>
                        )}
                      </div>
                    )}
                </div>
              </div>
              <div className="w-full md:w-1/3 primarylogo">
                {settings.hero_proceeds_text === '1' && localLanguage.general_proceeds_benefit !== '' &&
                  <div
                    className={heroProceedsTextClasses}
                    dangerouslySetInnerHTML={{
                      __html: localLanguage.general_proceeds_benefit
                    }}
                  />
                }
                {settings.webcharityurl !== "" ? (
                  <a href={settings.webcharityurl} target="_blank" rel="noopener noreferrer">
                    <img src={settings.primary_right_logo} alt={localLanguage.general_proceeds_benefit} className="block p-6 mx-auto primaryRightLogoClass " />
                  </a>
                ) : (
                  <img src={settings.primary_right_logo} alt={localLanguage.general_proceeds_benefit} className="block p-6 mx-auto primaryRightLogoClass" />
                )}
              </div>
            </div>
          </div>
        </div>
        {+settings.hero_logos_on_mobile !== 0 && (
          <div className={mobileLogosClassName}>
            <div className="w-full md:w-1/2" style={{zIndex: 1}}>
              <img src={settings.primary_left_logo} alt="" className="block p-6 mx-auto primaryLeftLogoClass" />
            </div>
            <div className="w-full md:w-1/2" style={{zIndex: 1}}>
              <img src={settings.primary_right_logo} alt="" className="block p-6 mx-auto primaryRightLogoClass" />
            </div>
          </div>
        )}
      </div>
    );
  }
}
Hero.contextType = AppContext;

export default Hero;
