import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Validator from "validatorjs";
import { request as xhr, queryString as qs } from "../utils/request";
import { AppContext } from "../utils/context";
import { appEnv } from "../utils/constants";

import CheckoutSteps from "../components/CheckoutSteps";
import CheckoutTickets from "../components/CheckoutTickets";
import CheckoutSummary from "../components/CheckoutSummary";
import CheckoutPersonalInfo from "../components/CheckoutPersonalInfo";
import CheckoutVerify from "../components/CheckoutVerify";
import CheckoutThankYou from "../components/CheckoutThankYou";
import CheckoutProcessing from "../components/CheckoutProcessing";
import CheckoutEnvelope from "../components/CheckoutEnvelope";
import CheckoutError from "../components/CheckoutError";
import CheckoutAgeVerification from "../components/CheckoutAgeVerification";
import {
  browserName,
  fullBrowserVersion,
  osName,
  osVersion,
  isMobile,
  mobileVendor,
  mobileModel,
  deviceDetect
} from "react-device-detect";
import classnames from 'classnames';

class Checkout extends Component {
  constructor(props, context) {
    super(props);

    const { localLanguage } = context;

    let subscribeOptions = [];
    if (+context.settings.checkout_subscribe_sms > 0) {
      subscribeOptions.push({
        text: localLanguage.checkout_subscribe_sms,
        value: "text",
        checked: +context.settings.checkout_subscribe_sms > 0 ? true : false,
        id: "subscribe-text"
      });
    }
    if (+context.settings.checkout_subscribe_email > 0) {
      subscribeOptions.push({
        text: localLanguage.checkout_subscribe_email,
        value: "email",
        checked: +context.settings.checkout_subscribe_email > 0 ? true : false,
        id: "subscribe-email"
      });
    }

    this.state = {
      loading: true,
      activeIndex: 1,
      activeSteps: [1],
      cartTotal: 0,
      cart: [],
      envelope: 0,
      beneficiaryId: undefined,
      event: context.event,
      eventSubscribe: false,
      paysafe_status: false,
      paysafe_after_payclick: false,
      shift4_after_payclick: false,
      tickets_error: false,
      envelope_error: false,
      personal_info_error: false,
      validation: new Validator({}, {}),
      error: {
        code: 0,
        message: "",
        action: "default"
      },
      payment: {
        payToken: ''
      },
      order: {
        id: 0,
        hash: "",
        orderstatus: 0,
        error: null
      },
      personal: {
        id: 0,
        first_name: "",
        last_name: "",
        title: "",
        email: "",
        email_confirmation: "",
        phone: "",
        phone_home: "",
        phone_work: "",
        address: "",
        suite: "",
        city: "",
        state: "",
        country: context.settings.country || "USA",
        zip_code: "",
        latitude: "",
        longitude: "",
        jurisdiction: "",
        age_range: "",
        showRecipientForm: 0,
        recipientCount: 0,
        recipientList: [],
        subscribe: subscribeOptions,
        agreement: [
          {
            text: localLanguage.checkout_agreement_age,
            value: "oldenough",
            checked: 0
          },
          {
            text: localLanguage.checkout_agreement_rules,
            value: "rules",
            checked: 0
          }
        ],
        saveInfo: [
          {
            text: "Save my personal information for my next visit",
            value: "saveinfo",
            checked: true
          }
        ]
      },
      invalidEmailError: null,
      displayAddonPPs: false,
      shift4Loading: true,
      paysafeLoading: true,
      ageVerificationModalOpen: false,
      needRestorePersonalData: false,
      needRestoreCartData: false,
      encodedPersonalData: "",
      encodedCartData: "",
      encodedAddressData: "",
      isDateOfBirthValidated: false
    };
  }

  componentDidMount = () => {
    // Force a page reload when navigating back - this is to force mobile browsers to reload the page instead of serving a cached file
    window.onpageshow = event => {
        if (event.persisted) {
            window.location.reload();
        }
    };

    const { history, location } = this.props;
    let queryString = qs.parse(location.search);

    if (history.location.pathname.indexOf("/thank-you") > -1 && typeof (queryString.orderId) === "undefined") {
      history.push("/select-tickets");
    }

    if (typeof (queryString.encodedAddressData) != "undefined") {
      this.setState({
        encodedPersonalData: queryString.encodedPersonalData,
        needRestorePersonalData: true
      });
    }

    if (typeof (queryString.encodedCartData) != "undefined") {
      this.setState({
        encodedCartData: queryString.encodedCartData,
        needRestoreCartData: true
      });
    }

    if (typeof (queryString.encodedAddressData) != "undefined") {
      this.setState({
        encodedAddressData: queryString.encodedAddressData,
        needRestorePersonalData: true
      });
    }

    // Handle Stripe payment cancel()
    const { config, localLanguage } = this.context;
    const stripePaymentInProgress = sessionStorage.getItem('stripePaymentInProgress');
    if (config.webPayments === 'stripe' && stripePaymentInProgress === 'true' && history.location.pathname.indexOf("/payment-info") > -1) {
      this.setState({ error: {
        code: 100,
        message: localLanguage.general_problem_processing
      }})
    };

    this.setState(
      {
        order: {
          id: queryString.orderId ? queryString.orderId : 0,
          hash: queryString.hash ? queryString.hash : 0
        },
        displayAddonPPs: location?.state?.pricePointKey >= 0 ? true : false
      },
      () => this.handleCallback()
    );
  };

  componentWillUnmount = () => {
    const { cookies } = this.props;
    cookies.remove("order");
    cookies.remove("cart");
    cookies.remove("envelope");

    // Remove session storage item 'stripePaymentInProgress' used to track stripe payment
    sessionStorage.removeItem('stripePaymentInProgress');
  };

  handleCallback = () => {
    const { history } = this.props;
    let queryString = qs.parse(this.props.location.search);
    if (typeof (queryString.orderId) !== "undefined" || typeof (queryString.hash) !== "undefined") { // if user provided orderId or hash in the URL
      // if user is on thank you page and provided a valid orderId and hash
      if (history.location.pathname.indexOf("/thank-you") > -1 && typeof (queryString.orderId) !== "undefined" && typeof (queryString.hash) !== "undefined") {
        this.getOrder();
      } else { // if user provided orderId or hash in the URL but not on thank-you page, then redirect to landing page
        history.push('/');
      }
    }
    else { // if not thank-you page (assuming user is on checkout page)
      this.setup();
    }
  }

  loadPaysafe = () => {
    const script = document.createElement("script");
    script.src =
      "https://hosted.paysafe.com/checkout/v1/latest/paysafe.checkout.min.js";
    script.async = true;
    script.onload = () => {
      this.setState({
        loading: false
      });
    };
    document.body.appendChild(script);
  };

  geofenceError = () => {
    const { localLanguage } = this.context;
    var error = { ...this.state.error };
    error.code = 2;
    error.message = localLanguage.general_geofence_outside_bounds;
    this.setState({ error });
  }

  checkMaxMind = () => {
    const script = document.createElement("script");
    script.src = "https://geoip-js.com/js/apis/geoip2/v2.1/geoip2.js";
    script.async = true;
    script.onload = () => {
      this.setState({
        loading: false
      });
      window.geoip2.city(this.mmSuccess, this.mmError);
    };
    document.body.appendChild(script);
  };

  initPaysafe = () => {
    const { cartTotal, personal } = this.state;
    const { localLanguage, settings } = this.context;

    this.setState({ paysafe_status: true, paysafeLoading: false });
    let self = this;

    // Get country ISO name
    let isoCountryCode = "";
    switch (personal.country) {
      case "CAN":
        isoCountryCode = "CA";
        break;
      case "USA":
        isoCountryCode = "US";
        break;
    }

    // strip first two chars from the localLanguage.setLocale
    const lang = localLanguage.setLocale.substring(0, 2);

    // Paysafe only supports 'en_US' and 'fr_CA'
    let paysafeLanguage = (lang === 'fr') ? 'fr_CA' : 'en_US';

    let paysafeEncoded = settings.payment_auth;
    let paysafeRequest = {
      amount: cartTotal * 100,
      currency: settings.currency,
      environment: (settings.payment_paysafe_env === "production") ? "LIVE" : "TEST",
      locale: paysafeLanguage,
      companyName: settings.client_name,
      holderName: personal.first_name + ' ' + personal.last_name,
      billingAddress: {
        country: isoCountryCode,
        zip: personal.zip_code,
        state: personal.state,
        city: personal.city,
        street: personal.address,
        street2: personal.suite
      },
      profile: {
        email: personal.email,
        phone: personal.phone
      }
    };

    if (appEnv === "production")
      settings.paysafe_logo !== "" ? paysafeRequest.imageUrl = settings.paysafe_logo : paysafeRequest.imageUrl = settings.header_logo;

    window.paysafe.checkout.setup(
      paysafeEncoded,
      paysafeRequest,
      function (instance, error, result) {
        if (error) {

          let errorObj = {
            ...self.state.payment,
            "code": error.code,
            "message": error.displayMessage
          }

          self.setState(prevState => ({
            error: errorObj,
            payment: errorObj,
          }));
          self.saveOrder();

          return false;

        }
        if (result.token) {

          if (result.paymentMethod === "Cards") {
            let payment = { ...self.state.payment };
            payment.payToken = result.token;
            self.setState({ payment: payment }, () => self.processOrder());
          }

          if (result.paymentMethod === "DirectDebit") {
            // use AJAX to send result.token to your merchant server to take DD payment
          }

          if (result.paymentMethod === "Interac") {
            // use AJAX to send result.token to your merchant server to take Interac payment
          }
          instance.close();
          self.setState({ paysafe_after_payclick: true });
        }
      },
      function (stage) {
        if (stage === "BeforePayment") {
          // No payment have been made
          // self.lastStep();
          self.cancelPayment();
        } else if (stage === "DuringPayment") {
          // Token have been issued, but the checkout overlay was closed from the user before instance flow control method was invoked
        } else if (stage === "AfterPayment") {
          const { paysafe_after_payclick } = self.state;
          if (!paysafe_after_payclick) {
            self.setState({ error: {
              code: 100,
              message: localLanguage.general_problem_processing
          }})
          }
          // Closed either via instance.close method or by the user from the success screen

        }
      }
    );
  };

  initShift4 = (order) => {
    const { localLanguage } = this.context;
    let self = this;
    window.$(function() {
        self.setState({ shift4Loading: false });
        window.$(".checkoutProcessing").i4goTrueToken({
            server: order.i4goServer,
            accessBlock: order.i4goAccessblock,
            url: order.i4goUrl,
            self: document.location,
            template: "bootstrap3-horizontal",
            frameContainer: "i4goFrame",
            frameAutoResize: true,
            language: localLanguage.setLocale.substring(0,2) ?? 'en',
            i4goInfo: true,
            onSuccess: function(form, data) {
                // console.log("i4go success", data);
            },
            onFailure: function(form, data) {
                // console.log("i4go failure", data);
                self.setState({ error: {
                    code: 100,
                    message: localLanguage.general_problem_processing
                }})
            },
            onComplete: function(form, data) {
                // console.log("i4go complete", data);
                self.setState({ shift4_after_payclick: true });
                let payment = { ...self.state.payment };
                payment.payToken = data.i4go_response !== "SUCCESS" ? data.i4go_response: data.i4go_uniqueid;
                self.setState({ payment: payment }, () => self.processOrder());
            },
            onWalletInit: function(wallet, enabled, reason) {
                console.log("i4go wallet init", wallet);
            },
            // formPaymentResponse: "customNameFori4go_response",
            // formPaymentResponseCode: "customNameFori4go_responsecode",
            // formPaymentResponseText: "customNameFori4go_responsetext",
            // formPaymentMaskedCard: "customNameFori4go_maskedcard",
            // formPaymentToken: "customNameFori4go_uniqueid",
            // formPaymentTokenExpMonth: "customNameFori4go_expirationmonth",
            // formPaymentTokenExpYear: "customNameFori4go_expirationyear",
            // formPaymentType: "customNameFori4go_cardtype",
            // formCardholderName: "customNameFori4go_cardholdername",
            // formStreetAddress: "customNameFori4go_streetaddress",
            // formPostalCode: "customNameFori4go_postalcode",
            formExtendedCardData: "customNameFori4go_extendedcarddata",
            formApplePayToken: "customNameFori4go_applepaytoken",
            formGooglePayToken: "customNameFori4go_googlepaytoken"
        })
    })
  }

  getOrder() {
    const { cart } = this.state;
    const { config, localLanguage } = this.context;
    const { cookies } = this.props;
    let order = config.webPayments === 'stripe' && cookies.get('order') ? cookies.get('order') : this.state.order;
    if (+order.id > 0) {
      return xhr
        .get(`api:/web/order/${order.id}`, {})
        .catch(err => {
          var error = { ...this.state.error };
          error.code = 10;
          error.message = err.message;
          error.action = "return";
          this.setState({ error });
          return err;
        })
        .then(resp => {
          if (+resp.id > 0) {
            order['orderstatus'] = resp.orderstatus;
            if (resp.orderdata && resp.orderdata.error)
                order['error'] = {
                    'title': localLanguage[resp?.orderdata?.error[0]] ?? null,
                    'message': localLanguage[resp?.orderdata?.error[1]] ?? null
                };
            if (resp.paymentdata && resp.paymentdata.error)
                order['error'] = {
                    'title': localLanguage.general_were_sorry ?? null,
                    'message': resp?.paymentdata?.error?.message ?? null
                };

            this.setState({
              loading: false,
              order: order,
              cart: resp.cartData ? resp.cartData : cart,
              personal: resp.orderdata,
              envelope: +resp.envelope,
              cartTotal: resp.totalcost
            });
          }
          return resp;
        });
    }
  }

  ValidateLocationSingle = (geoData, position) => {
    let self = this;

    var latTolerance = 0.01;
    var longTolerance = 0.01;
    var state = geoData?.state ?? null;

    // Check if within bounds and tolerance
    let isOutsideBounds = position.coords.latitude > geoData.border.n + latTolerance ||
      position.coords.latitude < geoData.border.s - latTolerance ||
      position.coords.longitude < geoData.border.w - longTolerance ||
      position.coords.longitude > geoData.border.e + longTolerance;

    if (isOutsideBounds) {
      this.geofenceError();
    }

    //If we reached here, they are within the bounds and within tolerance.  Align them to a resolution lookup to the geo data
    var xRes = (geoData.res - 1) / (geoData.border.e - geoData.border.w)
    var yRes = (geoData.res - 1) / (geoData.border.n - geoData.border.s)
    var xLookup = Math.floor((position.coords.longitude - geoData.border.w) * xRes)
    var yLookup = Math.floor((geoData.res - 1) - ((position.coords.latitude - geoData.border.s) * yRes))  //This one gets inverted to align with bitmap data

    //Because we allow for tolerance we need to ensure the numbers properly align to index space
    if (xLookup < 0) { xLookup = 0; }
    if (xLookup >= geoData.Res) { xLookup = geoData.res - 1; }
    if (yLookup < 0) { yLookup = 0; }
    if (yLookup >= geoData.Res) { yLookup = geoData.res - 1; }

    //Use the lookups to test the geo data if the location is within borders or not
    //The geo data is set as an array of hex data, each element in the array is a row in the map
    //Each row is a hex string representing the bits in that map

    var geoRowData = geoData.data[yLookup];

    // Check if geoRowData is defined and is a string like &16-080ff01&42-0
    if (typeof geoRowData !== 'string') {
        this.geofenceError();
        return; // Exit the function if geoRowData is not a string
    }

    if (geoData.compression == "True")
      geoRowData = self.DecompressRow(geoRowData);

    //There are 4 bits per character, find the character
    var geoElementIndex = Math.floor(xLookup / 4)
    var geoElementBitMask = 1 << (xLookup - (geoElementIndex * 4))
    var geoElementCharacter = geoRowData.charAt(geoElementIndex)
    var geoHex = parseInt(geoElementCharacter, 16);

    //Test if the bit mask is within the hex data, if so this location is on the map
    if ((geoElementBitMask & geoHex) == geoElementBitMask) {
      let personal = { ...self.state.personal };
      personal["jurisdiction"] = state;
      this.setState({ personal });
      return true;
    }
    else {
      this.geofenceError();
    }
  }

  ValidateLocationArray = (geoData, position) => {
    let self = this;

    var latTolerance = 0.01;
    var longTolerance = 0.01;
    var geoDataLocal = geoData;
    var found = 0;
    var state = "";

    for (var i = 0; i < geoDataLocal.length && found == 0; i++) {
      state = geoDataLocal[i].state ?? null;

      if (position.coords.latitude < geoDataLocal[i].border.n + latTolerance &&
        position.coords.latitude > geoDataLocal[i].border.s - latTolerance &&
        position.coords.longitude > geoDataLocal[i].border.w - longTolerance &&
        position.coords.longitude < geoDataLocal[i].border.e + longTolerance) {
        found = 1;
      }

      //this only means that the position was found within the bounding box for the province,
      // there can be overlap between the province's bounding boxes so need to check further
      if (found == 1) {

        var foundBoundary = i;//where we broke out

        //If we reached here, they are within a bounds and within tolerance.  Align them to a resolution lookup to the geo data
        var xRes = (geoDataLocal[foundBoundary].res - 1) / (geoDataLocal[foundBoundary].border.e - geoDataLocal[foundBoundary].border.w)
        var yRes = (geoDataLocal[foundBoundary].res - 1) / (geoDataLocal[foundBoundary].border.n - geoDataLocal[foundBoundary].border.s)
        var xLookup = Math.floor((position.coords.longitude - geoDataLocal[foundBoundary].border.w) * xRes)
        var yLookup = Math.floor((geoDataLocal[foundBoundary].res - 1) - ((position.coords.latitude - geoDataLocal[foundBoundary].border.s) * yRes))  //This one gets inverted to align with bitmap data

        //Because we allow for tolerance we need to ensure the numbers properly align to index space
        if (xLookup < 0) { xLookup = 0; }
        if (xLookup >= geoDataLocal[foundBoundary].Res) { xLookup = geoDataLocal[foundBoundary].res - 1; }
        if (yLookup < 0) { yLookup = 0; }
        if (yLookup >= geoDataLocal[foundBoundary].Res) { yLookup = geoDataLocal[foundBoundary].res - 1; }

        //Use the lookups to test the geo data if the location is within borders or not
        //The geo data is set as an array of hex data, each element in the array is a row in the map
        //Each row is a hex string representing the bits in that map

        var geoRowData = geoDataLocal[foundBoundary].data[yLookup];

        // Check if geoRowData is defined and is a string like &16-080ff01&42-0
        if (typeof geoRowData !== 'string') {
            this.geofenceError();
            return; // Exit the function if geoRowData is not a string
        }

        if (geoDataLocal[foundBoundary].compression == "True")
          geoRowData = self.DecompressRow(geoRowData);

        //There are 4 bits per character, find the character
        var geoElementIndex = Math.floor(xLookup / 4)
        var geoElementBitMask = 1 << (xLookup - (geoElementIndex * 4))
        var geoElementCharacter = geoRowData.charAt(geoElementIndex)
        var geoHex = parseInt(geoElementCharacter, 16);

        //Test if the bit mask is within the hex data, if so this location is on the map
        if ((geoElementBitMask & geoHex) == geoElementBitMask) {
          let personal = { ...self.state.personal };
          personal["jurisdiction"] = state;
          this.setState({ personal });
          return true;
        }
        else {
          //this was within the province's bounding box but not in the province
          found = 0;
        }
      }//if found
    }//loop

    if (found == 0) {
      this.geofenceError();
    }
  }

  DecompressRow = (rowData) => {
    //&16-080ff01&42-0 - form of rowData

    var outputRow = "";

    for (var i = 0; i < rowData.length; i++) {
      var myChar = rowData[i];

      if (myChar == "&") {
        var startPos = i + 1;
        var endPos = rowData.indexOf("-", startPos);
        i = endPos + 1;

        var numberOfRepeats = rowData.substring(startPos, endPos);
        var repeatingChar = rowData[i];

        //add the repeatingChar
        for (var j = 0; j < parseInt(numberOfRepeats); j++) {
          outputRow += repeatingChar;
        }
      }
      else {
        outputRow += rowData[i];
      }
    }

    return outputRow;
  }

  setup = () => {
    let self = this;
    const { event } = this.state;
    const { settings, localLanguage, config } = this.context;
    const { history } = this.props;
    if (config.webPayments === 'paysafe') this.loadPaysafe(); // only init paysafe if enabled in nova

    // redirect '/select-tickets' to homepage if event.shopify_enabled
    if (+event.shopify_enabled === 1 && this.props.location.pathname === "/select-tickets") {
      window.location.href = "/";
    }

    let promise = Promise.resolve(true);
    if (
      settings.bitmask_url !== "" ||
      settings.web_geofence_state !== "" ||
      settings.web_geofence_country !== ""
    ) {
      promise = self.getPosition();
    }

    promise.then(resp => {
      let prices = event.prices;
      let addonEvents = event.addons ?? false;
      let loadCart = [];

      const { state } = this.props.location;

      if (prices) {
        Object.keys(prices).map((v, k) => {
          return loadCart.push({
            ppId: prices[v].id,
            tickets: prices[v].quantity,
            cost: prices[v].price,
            qty: state ? (state.pricePointKey === k ? 1 : 0) : 0,
            disabled: prices[v].disabled,
            eventId: prices[v].eventId,
            eventName: event.title,
            gameType: localLanguage[`general_game_${event.game_id}_title`],
            gameId: event.game_id,
            isSTP: event.game_id === 1 ? true : false,
            prizeLabel: event.game_id === 4 ? localLanguage.general_calendar_prize : localLanguage.general_prize,
            prize: event.prize,
            jackpotLabel: localLanguage.general_jackpot,
            jackpot: event.jackpot,
            isFirstPricePoint: k === 0 ? true : false,
            isAddonPP: false,
            isFirstEverAddonPP: false,
            hidden: false
          });
        });
      }

      if (addonEvents) {
        Object.values(addonEvents).map((addon, key) => Object.values(addon.prices).forEach((pp, ppKey) => {
          return loadCart.push({
            ppId: addon.prices[ppKey].id,
            tickets: addon.prices[ppKey].quantity,
            cost: addon.prices[ppKey].price,
            qty: 0,
            disabled: addon.prices[ppKey].disabled,
            eventId: addon.prices[ppKey].eventId,
            eventName: addon.title,
            gameType: localLanguage[`general_game_${addon.game_id}_title`],
            gameId: event.game_id,
            isSTP: addon.game_id === 1 ? true : false,
            addonPrizeLabel: localLanguage.general_prize,
            addonPrize: addon.prize,
            addonJackpotLabel: localLanguage.general_jackpot,
            addonJackpot: addon.jackpot,
            addonGameId: addon.game_id,
            isFirstPricePoint: ppKey === 0 ? true : false,
            isAddonPP: true,
            isFirstEverAddonPP: key === 0 && ppKey === 0 ? true : false,
            hidden: true
          });
        }))
      }

      let loadPersonal = { ...this.state.personal };
      if (appEnv === "development") {
        loadPersonal["first_name"] = "Matt";
        loadPersonal["last_name"] = "annis";
        loadPersonal["email"] = "mannis@bumpcbn.com";
        loadPersonal["email_confirm"] = "mannis@bumpcbn.com";
        loadPersonal["phone"] = "6138971491";
        loadPersonal["phone_home"] = "";
        loadPersonal["phone_work"] = "";
        loadPersonal["address"] = "250 The Esplanade";
        loadPersonal["suite"] = "206";
        loadPersonal["city"] = "Toronto";
        loadPersonal["state"] = "ON";
        loadPersonal["zip_code"] = "M5A 4J6";
      }

      const { cookies } = this.props;
      const personalInfo = cookies.get("personal");
      const cart = cookies.get("cart");
      // const order = cookies.get("order");
      const envelope = cookies.get("envelope");

      if (personalInfo) {
        loadPersonal = personalInfo;
      }
      if (cart) {
        loadCart = cart;
      }

      if (this.state.needRestoreCartData) {
          const cartDataFromUrl = atob(this.state.encodedCartData);
          const cartData = JSON.parse(cartDataFromUrl);
          cartData.forEach((item, index) => {
            if (loadCart[index].ppId === item.ppId) {
              loadCart[index].qty = item.qty;
          }
        })
      }

      if (this.state.needRestorePersonalData) {
        let personalData = atob(this.state.encodedPersonalData);
        let addressData = atob(this.state.encodedAddressData);
        personalData = JSON.parse(personalData);
        addressData = JSON.parse(addressData);
        loadPersonal['age_range'] = personalData.age_range;
        loadPersonal["first_name"] = personalData.first_name;
        loadPersonal["last_name"] = personalData.last_name;
        loadPersonal["email"] = personalData.email;
        loadPersonal["email_confirmation"] =personalData.email;
        loadPersonal["phone"] = personalData.phone;
        loadPersonal["phone_home"] = personalData.phone_home;
        loadPersonal["phone_work"] = personalData.phone_work;
        loadPersonal['id'] = personalData.id;
        loadPersonal["address"] = addressData.address;
        loadPersonal["suite"] = addressData.suite;
        loadPersonal["city"] = addressData.city;
        loadPersonal["state"] = addressData.state;
        loadPersonal["zip_code"] = addressData.zip_code;
      }

      let callback = this.calculate;
      if (history.location.pathname.indexOf("/process-order") > -1) callback = this.processOrder;
      if (history.location.pathname.indexOf("/thank-you") > -1) callback = this.getOrder;
      // this call can result in 2 instances of select-tickets being on the browser stack but ok with that since
      // it results in preventing browser forward navigation from home screen past the select Ticket screen in most cases
      if (!cart && (!this.state.needRestoreCartData && !this.state.encodedAddressData != "")) {
        history.push("/select-tickets");
      } else if (event.game.id === 3 && !envelope) {
        // in this case if we are currently on the pick-envelope screen do not push it pnto the browser stack again
        if (history.location.pathname.indexOf("/pick-envelope") < 0) {
          history.push("/pick-envelope");
        }
      }
      this.setState(
        {
          loading: false,
          cart: loadCart,
          personal: loadPersonal,
          envelope: +envelope
        },
        callback
      );
    });
  };

  getPosition = async () => {
    let self = this;
    const { settings, localLanguage } = this.context;

    const geoData = await fetch(`${settings.bitmask_url}`, {}).then(response => response.text()).catch(err => {
      this.geofenceError();
    }).then(text => {
      let data = JSON.parse(text.replace("var geoData = ", '').replace(/[a-z]+:/gi, function (x) {
        return '"' + x.replace(":", "") + '":';
      }));
      return data;
    });

    return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        timeout: 5000
      });
    })
      .then(position => {
        if (position === "ignore") return "ignore";
        let personal = { ...self.state.personal };
        personal["latitude"] = position.coords.latitude;
        personal["longitude"] = position.coords.longitude;
        this.setState({ personal });
        return position.coords;
      })
      .catch(err => {
        if (settings.maxmind_enabled) {
          this.checkMaxMind();
          return "ignore";
        }

        let message = "";
        switch (err.code) {
          case err.PERMISSION_DENIED:
            message = localLanguage.general_allow_browser_location;
            break;
          case err.POSITION_UNAVAILABLE:
            message = localLanguage.general_no_location_info;
            break;
          case err.TIMEOUT:
            message = localLanguage.general_location_time_out;
            break;
          default:
            message = localLanguage.general_location_unknown_error;
        }
        var error = { ...this.state.error };
        error.code = err.code;
        error.message = message;
        error.action = "default";
        this.setState({ error });
        return false;
      })
      .then(coords => {
        if (coords === false) {
          return false;
        }
        if (coords === "ignore") {
          return "ignore";
        }

        let position = {
          coords: {
            latitude: coords.latitude,
            longitude: coords.longitude
          }
        };

        // Ensure in proper state to validate location
        let dataIsInvalid = position == null || geoData == null;
        if (dataIsInvalid) {
          this.geofenceError();
        }

        // Validate location
        if (geoData.constructor === Array) {
          self.ValidateLocationArray(geoData, position);
        }
        else {
          self.ValidateLocationSingle(geoData, position);
        }
      })
  };

  mmSuccess = position => {
    const { settings } = this.context;
    const fullName = position.subdivisions['0'].names.en; // Full name like "Ontario"
    const provinceCode = position.subdivisions['0'].iso_code;
    const countryCode = position.country.names.en.toLowerCase();
    let stateFound = false;
    if (provinceCode) {
      // Split settings.web_geofence_state by commas and convert to lowercase
      const geofenceStates = settings.web_geofence_state.toLowerCase().split(','); //this is a comma separated list of provinces ex: "ontario,quebec,nova scotia"
      const provinceLower = fullName.toLowerCase();
      if (geofenceStates.includes(provinceLower)) {
        stateFound = true;
        this.state.personal.jurisdiction = provinceCode;
      }
    }

    if (settings.web_geofence_state == '' && settings.web_geofence_country.toLowerCase() == countryCode) { 
      return position;
    }

    if ((settings.web_geofence_country != '' && settings.web_geofence_country.toLowerCase() != countryCode) || stateFound === false) {
      return this.mmError();
    }

    // Position verified
    return position;
  };

  mmError = error_message => {
    const { localLanguage } = this.context;
    var error = { ...this.state.error };
    error.code = "-1";
    error.message = localLanguage.general_location_unknown_error;
    error.action = "default";
    this.setState({ error });
    return false;
  };

  errorReturnHome = () => {
    const { cookies, history } = this.props;
    cookies.remove('order');
    cookies.remove("cart");
    history.push("/")
  }

  checkout = (recaptcha) => { // save order
    let self = this;
    const { cookies, history } = this.props;
    const { config, localLanguage } = this.context;
    this.saveOrder(recaptcha).catch(function (err) {
      return err;
    }).then(function (resp) {
        if (resp.status == 'failed') {
            self.setState({ error: {
                code: 100,
                message: resp.message
            }})
            return false;
        }
        // shift4
        if (config.webPayments === 'shift4') {
            if (resp.data.i4goAccessblock && resp.data.i4goServer) { // load up i4m
                self.initShift4(resp.data);
            } else { // do not load up i4m, instead direct the user to thank you page with error displayed
                self.setState({ error: {
                    code: 100,
                    message: localLanguage.general_problem_processing
                }})
                history.replace(); // clear history
                history.replace(`/thank-you?orderId=${resp.data.id}&hash=${resp.data.hash}`); // we have order id and hash now, so forward user to thank-you page
            }
        }

        // stripe
        if (config.webPayments === 'stripe' && resp.data.redirectUrl !== "") {
            sessionStorage.setItem('stripePaymentInProgress', 'true'); // add session to track stripe payment status
            window.location.href = resp.data.redirectUrl;
        }

        // paysafe
        if (config.webPayments === 'paysafe') self.initPaysafe();

        self.setState({ order: resp.data });
        cookies.set('order', resp.data);
    });
  };

  processOrder = () => {
    // On successful stripe payment, remove SessionStorage 'stripePaymentInProgress' variable
    sessionStorage.removeItem('stripePaymentInProgress');

    let self = this;

    const { localLanguage, config } = this.context;
    const { cookies, history } = this.props;

    let payToken = self.state.payment.payToken;

    let order = config.webPayments === 'stripe' ? cookies.get('order') : self.state.order; // since we do hosted payments with stripe we lose state "order"

    let event = self.state.event;

    return xhr.post(`api:/web/order/${order.id}/process`, { // process ecomm order
      payToken: payToken,
      id: order.id,
      orderToken: order.ordertoken,
      eventId: event.id,
      hash: order.hash,
      locale: localLanguage.setLocale.substring(0,2) || 'en'
    }).then(resp => {
      this.setState({loading: true});
      history.replace(); // clear history
      history.replace(`/thank-you?orderId=${order.id}&hash=${order.hash}`); // we have order id and hash now, so forward user to thank-you page

      if (!resp || resp.status === 'error' || resp === 401) {
        let error = {
          "code": 0,
          "message": resp?.message ?? localLanguage.general_problem_processing
        }
        this.setState({ error: error });
      } else if (resp.status === 'success') {
        setTimeout(() => {
          self.getOrder().then(respOrder => { // user is on thank-you page now, remove cookies. A page refresh here would pull in info from getOrder()
            cookies.remove('order');
            cookies.remove("cart");
            cookies.remove("envelope");
            cookies.remove("personal");
            cookies.remove("ambassador");
          })
        }, 5000);
        return false;
      } else { // fall through lets set an error code for this particular fall through
        let error = {
          "code": 2,
          "message": resp.message ? resp.message : localLanguage.general_order_error_two
        }
        this.setState({ error: error });
      }
      return resp;
    });
  };

  saveCart = () => {
    const { cookies } = this.props;

    const { personal, cart, order } = this.state;
    if (personal.saveInfo[0].checked) cookies.set("personal", personal);
    cookies.set("order", order);
    cookies.set("cart", cart);
    return true;
  };

  saveOrder = (recaptcha) => {
    this.saveCart();
    const { event, personal, order, cart, cartTotal, payment, envelope, beneficiaryId } = this.state;
    const { localLanguage } = this.context;
    const { cookies } = this.props;

    let ambassador = cookies.get("ambassador") ? cookies.get("ambassador") : null;
    let referrerCode = ambassador ? ambassador.referrer_code : null;

    let payload = {
      eventId: event.id,
      personal: personal,
      order: order,
      cart: cart,
      total: cartTotal,
      payment: payment,
      envelope: envelope,
      beneficiaryId: beneficiaryId > 0 ? beneficiaryId : undefined,
      referrerCode: referrerCode,
      platform: {
        browserName: browserName,
        fullBrowserVersion: fullBrowserVersion,
        osName: osName,
        osVersion: osVersion,
        isMobile: isMobile,
        mobileVendor: mobileVendor,
        mobileModel: mobileModel,
        deviceDetect: deviceDetect()
      },
      recaptcha: recaptcha,
      locale: localLanguage.setLocale.substring(0,2) || 'en'
    };

    let result;
    if (order.id > 0) {
      result = xhr.put(`api:/web/order/${order.id}`, payload);
    } else {
      result = xhr.post("api:/web/order", payload);
    }

    return result;
  };

  startPayment = () => { //seems unused
    this.saveCart();
    const { event, personal, order } = this.state;
    let payload = {
      eventId: event.id,
      orderId: order.id,
      customerId: personal.id
    };

    return xhr.post("api:/web/init-payment", payload).then(resp => {
      return resp;
    });
  };

  handleCart = e => {
    const { config } = this.context;
    const { event } = this.state;
    let cart = [...this.state.cart];
    let displayAddonPPs = this.state.displayAddonPPs;

    let key = e.target.id.replace("cart-", "");
    let val = parseInt(e.target.value, 10);

    if (isNaN(val)) {
        val = 0; // or whatever default value you want to use
    }
    if (val >= 10) {
      val = 10;
    } else if (val <= 0 || val == "") {
      val = 0;
    }

    cart[key].qty = val;

    if (config.option_allow_addons && event.addons) { // if addons are enabled at the tenant level
        let mainEventPPCount = event?.prices?.length ?? 0;

        if (Number(key) < mainEventPPCount) { // check if cart contains main event pp
            let totalMainCartQty = 0;
            cart.map(c => {
                if(!c.isAddonPP) return (totalMainCartQty += c.qty);
            });
            if (totalMainCartQty > 0 || !config.option_addons_require_main) { // display addon prices if cart contains atleast one main event pp
                displayAddonPPs = true;
            } else {
                displayAddonPPs = false;
                cart.map(c => {
                    c.qty = 0;
                });
            }
        }
    }
    this.setState({ cart, tickets_error: false, displayAddonPPs: displayAddonPPs }, this.calculate);
  };

  handleEnvelope = e => {
    const { cookies } = this.props;
    cookies.set("envelope", e.currentTarget.getAttribute('data-index'));
    this.setState({ envelope: +e.currentTarget.getAttribute('data-index'), envelope_error: false });
  };

  handleBeneficiary = (e) => {
    const value = e ? e.value : undefined;
    this.setState({ beneficiaryId: value, tickets_error: false });
  }

  handleChange = e => {
    let { name, value } = e.target;
    let personal = { ...this.state.personal };
    personal[name] = value;
    this.setState({ personal });
  };

  handlePhoneNumber = e => {
    let { name, value } = e.target;
    value = value.replace(/\D/g, "");
    let personal = { ...this.state.personal };
    personal[name] = value;
    this.setState({ personal });
  }

  handleGiftTicket = e => {
    let personal = { ...this.state.personal };
    if (e.target.checked) {
        personal.showRecipientForm = 1;
    } else {
        personal.showRecipientForm = 0;
        personal.recipientCount = 0;
        personal.recipientList = [];
    }
    this.setState({ personal });
  };

  handleSubscribe = e => {
    let { value, checked } = e.target;
    let personal = { ...this.state.personal };
    let subscribe = personal.subscribe;
    subscribe.forEach(sub => {
      if (sub.value === value) sub.checked = checked;
    });
    personal["subscribe"] = subscribe;
    this.setState({ personal });
  };

  handleDateOfBirthValidation = (value) => {
    this.setState({ isDateOfBirthValidated: value });
  };

  handleAgreement = e => {
    let { value, checked } = e.target;
    let personal = { ...this.state.personal };
    let agreement = personal.agreement;
    agreement.forEach(agree => {
      if (agree.value === value) agree.checked = checked;
    });
    personal["agreement"] = agreement;
    this.setState({ personal });
  };

  handleSaveInfo = e => {
    let { value, checked } = e.target;
    let personal = { ...this.state.personal };
    let saveInfo = personal.saveInfo;
    saveInfo.forEach(info => {
      if (info.value === value) info.checked = checked;
    });
    personal["saveInfo"] = saveInfo;
    this.setState({ personal });
  };

  calculate = () => {
    let cart = this.state.cart;
    let total = 0;
    cart.map(c => {
      return (total += c.qty * c.cost);
    });
    this.setState({ cartTotal: total });
  };

  validateCart = () => {
    this.saveCart();
    const { history } = this.props;
    const { event } = this.state;
    const { localLanguage, settings } = this.context;
    const rules = {
      cartTotal: "min:1"
    };
    const customMessages = {
      "min.cartTotal": localLanguage.general_select_tickets_continue,
    };

    if (this.context.event.beneficiaryenabled === 1 && +settings.mandatory_beneficiary === 1) {
      customMessages["required.beneficiaryId"] = localLanguage.general_select_beneficiary_continue;
      rules["beneficiaryId"] = "required"
    }

    let validation = new Validator(this.state, rules, customMessages);

    if (validation.passes()) {
      event.game.id === 3 ? history.push("/pick-envelope") : history.push("/personal-info");
    } else {
      this.setState({ validation, tickets_error: true });
    }
  };

  validateCartFinal = () => {
    this.saveCart();
    const { history } = this.props;
    const { localLanguage, settings } = this.context;
    const rules = {
      cartTotal: "min:1",
      envelope: "min:1"
    };
    const customMessages = {
      "min.envelope": localLanguage.general_select_env_continue
    };

    if (this.context.event.beneficiaryenabled === 1 && +settings.mandatory_beneficiary === 1) {
      customMessages["required.beneficiaryId"] = localLanguage.general_select_beneficiary_continue;
      rules["beneficiaryId"] = "required"
    }

    let validation = new Validator(this.state, rules, customMessages);

    if (validation.passes()) {
      history.push("/personal-info");
    } else {
      this.setState({ validation, envelope_error: true, tickets_error: true });
    }
  };

  validateCartTickets = () => {
    this.saveCart();
    const { history } = this.props;
    const { localLanguage, settings } = this.context;
    const rules = {
        cartTotal: "min:1"
    };
    const customMessages = {
        "min.cartTotal": localLanguage.general_select_tickets_continue,
    };

    if (this.context.event.beneficiaryenabled === 1 && +settings.mandatory_beneficiary === 1) {
      customMessages["required.beneficiaryId"] = localLanguage.general_select_beneficiary_continue;
      rules["beneficiaryId"] = "required"
    }

    let validation = new Validator(this.state, rules, customMessages);

    if (validation.passes()) {
        history.push("/personal-info");
    } else {
        this.setState({ validation, tickets_error: true });
    }
  };

  handleStateRecipientCount = (count, list) => {
    document.getElementById('recipientLimit').value = count;
    let personal = this.state.personal;
    personal.recipientCount = count;
    personal.recipientList = list;
    this.setState(personal);
  }

  validateRecipientList = () => {
    let list = this.state.personal.recipientList;
    let filteredList = list.filter(function (e) { return e['recipientListEmail'] !== ""; });
    this.handleStateRecipientCount(filteredList.length, filteredList);
  }

  emailValidation = (email) => {
    const regex = /^[-!#-'*+\/-9=?^-~]+(?:\.[-!#-'*+\/-9=?^-~]+)*@[-!#-'*+\/-9=?^-~]+(?:\.[-!#-'*+\/-9=?^-~]+)+$/i;
    if (regex.test(email)) {
      return true;
    }
    else {
      return false;
    }
  }

  validatePersonal = () => {
    this.saveCart();
    const { history } = this.props;
    const { settings, localLanguage } = this.context;
    const { personal, isDateOfBirthValidated } = this.state;

    // Check if subscribe-text is checked
    const isSubscribeTextChecked = personal.subscribe.some(sub => sub.id === "subscribe-text" && sub.checked);
    const ageVerificationRequiredSms = +settings?.age_verification_required_sms === 1;

    const rules = {
      "first_name": "required|string",
      "last_name": "required|string",
      "email": "required|email|confirmed",
      "email_confirmation": "required|email",
      "phone": "required|digits:10",
      "address": "required|string",
      "city": "required|string",
      "country": "required|string",
      "state": "required|string",
      "zip_code": "required|string",
      "agreement.0.checked": "accepted",
      "agreement.1.checked": "accepted",
      "recipientList.*.recipientListFirstName": +settings.gifting_option > 0 && personal.showRecipientForm > 0 && "required|string",
      "recipientList.*.recipientListLastName": +settings.gifting_option > 0 && personal.showRecipientForm > 0 && "required|string",
      "recipientList": +settings.gifting_option > 0 && personal.showRecipientForm > 0 && "uniqueEmails",
      "recipientLimit": +settings.gifting_option > 0 && personal.showRecipientForm > 0 && personal.recipientCount < 1 && "required",
      "dateOfBirth": isSubscribeTextChecked && ageVerificationRequiredSms && !isDateOfBirthValidated ? "required|date" : ""
    };
    const customMessages = {
      "accepted.agreement.0.checked": localLanguage.general_field_is_required,
      "accepted.agreement.1.checked": localLanguage.general_field_is_required,
      "recipientLimit": localLanguage.general_null_recipient_error,
      "required.dateOfBirth": localLanguage.general_field_is_required
    };

    let personalAgreement = this.state.personal.agreement;
    personalAgreement.forEach((agreement, key) => {
      if(agreement.text === "") {
        delete rules[`agreement.${key}.checked`];
      }
    });
    const personalInfo = this.state.personal;
    this.state.personal.recipientList.forEach((recipient, i) => {
      rules[`recipientListEmail${i}`] = +settings.gifting_option > 0 && personal.showRecipientForm > 0 && "required|email|confirmed";
      rules[`recipientListEmail${i}_confirmation`] = +settings.gifting_option > 0 && personal.showRecipientForm > 0 && "required|email";
      personalInfo[`recipientListEmail${i}`] = recipient.recipientListEmail;
      personalInfo[`recipientListEmail${i}_confirmation`] = recipient.recipientListEmail_confirmation;
    });

    Validator.register('uniqueEmails', function(value, requirement, attribute){
      const uniqueEmails = new Set();
      for (const v of value) {
        if(uniqueEmails.has(v.recipientListEmail)) {
          return false;
        }
        uniqueEmails.add(v.recipientListEmail);
      }
      return true;
    })

    let validation = new Validator(personalInfo, rules, customMessages);
    if (validation.passes()) {
      if (this.emailValidation(personal.email)) {
        if (+settings.gifting_option > 0 && +personal.showRecipientForm > 0) {
          this.validateRecipientList();
        }
        if (this.context.config.option_stripe_age_verification_enable == true) {
            this.handleAgeVerification();
        } else {
          history.push("/confirm-order");
        }
        this.setState({ personal_info_error: false, invalidEmailError: null });
      } else {
        this.setState({ personal_info_error: true, invalidEmailError: localLanguage.general_invalid_email });
      }
    }
    this.setState({ validation });
  };

  validateConfirm = (recaptcha) => {

    this.setState({ isProcessLoading: true });
    this.saveCart();

    const { history } = this.props;
    const rules = this.state.event.game.id === 3 ? {
      envelope: "min:1",
      cartTotal: "min:1"
    } : {
      cartTotal: "min:1"
    };
    let validation = new Validator(this.state, rules);

    if (validation.passes()) {
      this.processToPayment()
    } else {
        this.setState({ validation, personal_info_error: true, isProcessLoading: false });
    }
  }
  handleAgeVerification = async () => {
    // make api call to /stripe/check-customer-verified with payload to check if customer is verified
    let payload = {
      email: this.state.personal.email,
      first_name: this.state.personal.first_name,
      last_name: this.state.personal.last_name,
      phone: this.state.personal.phone,
    }
    try {
      const response = await xhr.post(`api:/stripe/check-customer-verified`, payload);
      if (response.status == false) {
        this.setState({ ageVerificationModalOpen: true, isProcessLoading: false });
      } else {
        this.setState({ ageVerificationModalOpen: false, isProcessLoading: false });
        this.props.history.push("/confirm-order");
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleVerificationSuccess = () => {
    this.setState({ ageVerificationModalOpen: false});
    const { history } = this.props;
    history.push("/confirm-order");
  }

  handleCloseModal = () => {
    this.setState({ ageVerificationModalOpen: false});
  }


  cancelPayment = () => {
    this.setState({ paysafe_status: false });
  };

  processToPayment = () => {
    const { history } = this.props;
    history.push('/payment-info');
    this.checkout();
  }

  goToPrevious = () => {
    const { event } = this.state;
    switch (this.props.location.pathname) {
      case "/payment-info":
        this.props.history.push("/confirm-order");
        break;
      case "/confirm-order":
        this.props.history.push("/personal-info");
        break;
      case "/personal-info":
        event.game.id === 3 ? this.props.history.push("/pick-envelope") : this.props.history.push("/select-tickets");
        break;
      case "/pick-envelope":
        this.props.history.push("/select-tickets");
        break;
      case "/select-tickets":
        this.props.history.push("/");
        break;
      default:
        this.props.history.push("/select-tickets");
    }
  };

  getEnvelopeHeight = (url) => {
    let image = new Image();
    image.src = url;
    let height = image.height > 640 ? 640 : image.height;
    return height;
  }

  render() {
    const {
      cart,
      cartTotal,
      error,
      event,
      order,
      personal,
      validation,
      envelope,
      payment,
      paysafe_status,
      paysafe_after_payclick,
      loading,
      displayAddonPPs,
      shift4_after_payclick,
      shift4Loading,
      paysafeLoading,
      beneficiaryId
    } = this.state;

    const { config, settings, localLanguage } = this.context;
    const { pathname: path } = this.props.location;
    let envelopeBackgroundImageHeight = this.getEnvelopeHeight(settings.envelope_background_image);

    const heroBoxClassnames = classnames('container', 'content', 'm-auto', { 'box-it': +settings.checkout_box_highlighting === 1 });

    // shift4Loading is reset to true, to prevent this statement from constantly firing
    if (!shift4_after_payclick && !shift4Loading
      && config.webPayments === 'shift4'
      && path.indexOf("/payment-info") == -1) {
      this.setState({ error: {
        code: 100,
        message: localLanguage.general_problem_processing
      },
      shift4Loading: true})
    }

    personal.subscribe && personal.subscribe.forEach(option => {
        if (option['value'] === 'text') {
          option['text'] = localLanguage.checkout_subscribe_sms;
          option['id'] = "subscribe-text";
        }
        if (option['value'] === 'email') {
          option['text'] = localLanguage.checkout_subscribe_email;
          option['id'] = "subscribe-email";
        }
    });

    personal.agreement && personal.agreement.forEach(option => {
        if (option['value'] === 'oldenough') option['text'] = localLanguage.checkout_agreement_age;
        if (option['value'] === 'rules') option['text'] = localLanguage.checkout_agreement_rules;
    });

    let steps = [];
    this.state.event.game.id === 3 ?
      steps = [
        {
          active: path === "/select-tickets" ? true : false,
          title: localLanguage.general_select_tickets
        },
        {
          active: path === "/pick-envelope" ? true : false,
          title: localLanguage.general_pick_envelope
        },
        {
          active: path === "/personal-info" ? true : false,
          title: localLanguage.general_personal_info
        },
        {
          active: path === "/confirm-order" ? true : false,
          title: localLanguage.general_review_order
        },
        {
          active: (path === "/payment-info" || path === "/process-order") ? true : false,
          title: localLanguage.general_payment_info
        },
        {
          active: path === "/thank-you" ? true : false,
          title: localLanguage.general_print_tickets
        }
      ]
      :
      steps = [
        {
          active: path === "/select-tickets" ? true : false,
          title: localLanguage.general_select_tickets
        },
        {
          active: path === "/personal-info" ? true : false,
          title: localLanguage.general_personal_info
        },
        {
          active: path === "/confirm-order" ? true : false,
          title: localLanguage.general_review_order
        },
        {
          active: (path === "/payment-info" || path === "/process-order") ? true : false,
          title: localLanguage.general_payment_info
        },
        {
          active: path === "/thank-you" ? true : false,
          title: localLanguage.general_print_tickets
        }
      ];

    // Only for shift4
    if (this.context.config.webPayments === 'shift4' && window.location.href.includes('payment-info') && error.code === 0) {
        return <CheckoutProcessing
            title={localLanguage.general_payment_information}
            paysafe_after_payclick={paysafe_after_payclick}
            paysafe_status={paysafe_status}
            shift4Loading={this.state.shift4Loading}
            goToPrevious={this.goToPrevious}
        />
    }

    return (
      <div className=" mx-auto py-6 video-main video-bg" style={{ backgroundColor: settings.hero_background_color }}>
        <div className="video cover align-center align-middle">
          {settings.checkout_background_image && settings.checkout_background_image !== "" && (
            <img
              src={`${settings.checkout_background_image}`}
              alt=""
              className="opacity-75"
            />)
          }
        </div>
        <div className={heroBoxClassnames}>
          <h1 className="mb-6">{localLanguage.general_checkout}</h1>
          {error.code > 0 || error.message !== "" ? (
            <CheckoutError error={error} order={order} event={event} errorReturnHome={this.errorReturnHome}/>
          ) : (
            <div>
               {this.state.ageVerificationModalOpen && <CheckoutAgeVerification
                  personal={this.state.personal}
                  cart={this.state.cart}
                  eventId={this.state.event.id}
                  goToPayment={this.processToPayment}
                  showModal={this.state.ageVerificationModalOpen}
                  handleCloseModal={this.handleCloseModal}
                  handleVerificationSuccess={this.handleVerificationSuccess}
                  backgroundColor={settings.hero_background_color}
                  stripePublicKey= {this.context.config.stripe_publishable_key}
                />}
                <CheckoutSteps steps={steps} />
                <div className="checkoutBox">
                  <Switch>
                      <Route
                      path="/select-tickets"
                      exact
                      render={props => (
                          <CheckoutTickets
                          context={this.context}
                          cart={cart}
                          valid={validation.errorCount > 0 ? validation.errors.errors : {}}
                          tickets_error={this.state.tickets_error}
                          displayAddonPPsProp={displayAddonPPs}
                          goToPrevious={this.goToPrevious}
                          handleCart={this.handleCart}
                          beneficiaryId={beneficiaryId}
                          handleBeneficiary={this.handleBeneficiary}
                          validateCart={this.validateCart}
                          validateCartTickets={this.validateCartTickets}
                          {...props}
                          />
                      )}
                      />
                      <Route
                      path="/pick-envelope"
                      exact
                      render={props => (
                          <CheckoutEnvelope
                          context={this.context}
                          envelope={envelope}
                          envelopes={event.cards}
                          envelope_error={this.state.envelope_error}
                          tickets_error={this.state.tickets_error}
                          valid={validation.errorCount > 0 ? validation.errors.errors : {}}
                          scripts_pick_envelope={settings.scripts_pick_envelope}
                          envelope_number_color={settings.envelope_number_color}
                          envelope_background_image={settings.envelope_background_image}
                          envelopeBackgroundImageHeight={envelopeBackgroundImageHeight < +settings.envelope_background_default_height ? +settings.envelope_background_default_height : envelopeBackgroundImageHeight}
                          handleEnvelope={this.handleEnvelope}
                          validateCartFinal={this.validateCartFinal}
                          goToPrevious={this.goToPrevious}
                          />
                      )}
                      />
                      <Route
                      path="/personal-info"
                      exact
                      render={props => (
                          <CheckoutPersonalInfo
                          data={personal}
                          valid={
                              validation.errorCount > 0 ? validation.errors.errors : {}
                          }
                          settings={settings}
                          event={this.context.event}
                          payment={payment}
                          defaultCountry={settings.country}
                          giftingOption={settings.gifting_option}
                          recipientLimit={settings.recipient_limit}
                          invalidEmailError={this.state.invalidEmailError}
                          context={this.context}
                          handleChange={this.handleChange}
                          handlePhoneNumber={this.handlePhoneNumber}
                          handleSubscribe={this.handleSubscribe}
                          handleGiftTicket={this.handleGiftTicket}
                          handleStateRecipientCount={this.handleStateRecipientCount}
                          handleAgreement={this.handleAgreement}
                          handleSaveInfo={this.handleSaveInfo}
                          validatePersonal={this.validatePersonal}
                          goToPrevious={this.goToPrevious}
                          onDateOfBirthValidated={this.handleDateOfBirthValidation}
                          {...props}
                          />
                      )}
                      />
                      <Route
                      path="/confirm-order"
                      exact
                      render={props => <CheckoutVerify
                          orderRecaptchaRequired={this.context.config.option_require_recaptcha}
                          recaptchaKey={this.context.config.google_recaptcha_public_key}
                          recaptchaType={this.context.config.option_recaptcha_type}
                          settings={this.context.settings}
                          data={personal}
                          payment={payment}
                          context={this.context}
                          personal_info_error={this.state.personal_info_error}
                          giftingOption={settings.gifting_option}
                          validateConfirm={this.validateConfirm}
                          goToPrevious={this.goToPrevious}
                          {...props}
                      />}
                      />
                      <Route
                      path="/payment-info"
                      exact
                      render={props => (<CheckoutProcessing
                          title={localLanguage.general_payment_information}
                          paysafe_after_payclick={paysafe_after_payclick}
                          paysafe_status={paysafe_status}
                          paysafeLoading={paysafeLoading}
                          initPaysafe={this.initPaysafe}
                          goToPrevious={this.goToPrevious}
                          {...props}
                      />)
                      }
                      />
                      <Route
                      path="/process-order"
                      exact
                      render={props => (
                          <CheckoutProcessing
                          key={4}
                          title={localLanguage.general_please_wait}
                          content={localLanguage.general_processing_order}
                          {...props}
                          />
                      )}
                      />
                      <Route
                      path="/thank-you"
                      exact
                      render={props => (
                          <CheckoutThankYou
                          order={order}
                          cart={cart}
                          cartTotal={cartTotal}
                          event={event}
                          personal={personal}
                          giftingOption={settings.gifting_option}
                          {...props}
                          settings={this.context.settings}
                          loading={loading}
                          error={error}
                          />
                      )}
                      />
                  </Switch>
                  <CheckoutSummary
                    event={this.context.event}
                    cart={cart}
                    envelope={envelope}
                    total={cartTotal}
                    location={this.props.location}
                    cookies={this.props.cookies}
                  />
                </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
Checkout.contextType = AppContext;

export default Checkout;
