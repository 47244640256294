import React, { Component } from "react";
import { request, queryString } from "../utils/request";
import { AppContext } from "../utils/context";

class AmbassadorInput extends Component {
    constructor(props) {
        super(props);
        const qs = queryString.parse(this.props.location.search);
        this.state = {
            ambassador: null,
            referrerCode: qs.ref || "",
            successMsg: null,
            errorMsg: null
        };
    }

    componentDidMount() {
        const qs = queryString.parse(this.props.location.search);
        const cookies = this.props.cookies;

        if (qs.ref && this.props.event.id) {
            this.getAmbassador(qs.ref, this.props.event.id);
        } else if (cookies.get("ambassador")) {
            let ambassador = cookies.get("ambassador");
            this.setState({ ambassador: ambassador, referrerCode: ambassador.referrer_code });
        }
    }

    getAmbassador = async (ref, eventId) => {
        const cookies = this.props.cookies;

        let resp = await request.get(`api:/web/ambassador?ref=${ref}&eventId=${eventId}&lang=${this.context.locale.substring(0, 2)}`, {});

        if (resp.error) {
            this.setState({ ambassador: {}, errorMsg: resp.error, successMsg: null });
        } else {
            cookies.set("ambassador", resp.ambassador);
            this.setState({ 'ambassador': resp.ambassador, errorMsg: null, successMsg: resp.message });
        }

        return resp.ambassador;
    }

    handleChange = (e) => {
        this.setState({ referrerCode: e.target.value });
    };

    checkReferrerCode = () => {
        const { event } = this.props;
        const { localLanguage } = this.context;
        if (this.state.referrerCode.length >= 4) {
            this.getAmbassador(this.state.referrerCode, event.id);
        } else {
            this.setState({ errorMsg: localLanguage.general_ambassador_enter_valid_code, successMsg: null });
        }
    }

    render() {
        const {
            label,
            id
        } = this.props;
        const { localLanguage } = this.context;

        let labelClass = "block font-bold mb-2";
        return (
            <div className="ambassador-container text-center">
                <label className={labelClass} htmlFor={id}>
                    {label}
                </label>
                <div className="flex flex-col items-center">
                    <input
                        className="appearance-none block border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white w-full md:w-1/2"
                        container="w-full md:w-1/2 md:pr-3 mb-6 md:mb-0"
                        id={id}
                        name="referrerCode"
                        value={this.state.referrerCode}
                        placeholder={localLanguage.general_ambassador_enter_referrer_code}
                        onChange={this.handleChange}
                    />
                </div>
                {this.state.errorMsg && <p className="text-danger text-xs italic mb-4">{this.state.errorMsg}</p>}
                {this.state.successMsg && <p className="text-success text-xs italic mb-4">{this.state.successMsg}</p>}
                <button
                  onClick={this.checkReferrerCode}
                  className="cursor-pointer inline-block no-underline bg-accent hover:bg-accent-dark text-primary font-bold text-lg py-4 px-6 rounded shadow-outline"
                >
                  {localLanguage.general_ambassador_apply_referrer_code}
                </button>
            </div>
        );
    }
}

AmbassadorInput.contextType = AppContext;
export default AmbassadorInput;
