import React from 'react';
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import { Route, Switch, withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import CookieConsent from "react-cookie-consent";

import { request, queryString } from "./utils/request";
import { AppContext } from "./utils/context";
import {apiUrl} from './utils/constants';

import HeaderNav from "./components/HeaderNav";
import FooterPoweredBy from "./components/FooterPoweredBy";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Winners from "./pages/Winners";
import Checkout from "./pages/Checkout";
import ContentManaged from "./pages/ContentManaged";
import PrintSuper from "./pages/Print";
import CheckoutFreeTicket from "./pages/CheckoutFreeTicket";
import FooterLinks from "./components/FooterLinks";
import PaymentTypes from "./components/PaymentTypes";
import FooterBody from "./components/FooterBody";
import GrantAccess from "./pages/GrantAccess";
import PrintDiscountCodeSuper from "./pages/PrintDiscountCode";

const MyRoute = route => {
  if (route?.settings?.employee_raffle_on_off == 1 && route.cookies.get("employee_authorized") !== 'true') {
    return (
      <GrantAccess
      settings
      cookies={route.cookies}
      />
    );
  }
  return (
    <Route
      name={route.name}
      path={route.path}
      exact={route.exact}
      render={props => (
        <route.component
          cookies={route.cookies}
          title={route.title}
          page={route.page}
          {...props}
        />
      )}
    />
  )
};


class BlockedPage extends React.Component {
  render() {
    var x = document.getElementById("loader-wrapper");
    x.style.display = "none";
    return (
      <div style={{ textAlign: 'center', marginTop: '300px' }}>
        <Helmet>
          <title>Site Unavailable</title>
        </Helmet>
        <h1>Site Unavailable</h1>
        <p>This site is currently unavailable. If you're uncertain why you received this message, please don't hesitate to contact our support team at 1-800-815-0867 (Option 1)</p>
      </div>
    );
  }
}

// The below mappings are used by next-gen-dashboard to apply theme and color changes to the web app
// Mapping between toggle names and class names
const toggleClassNames = {
  displayLargestJackpot: 'main-largest-jackpot',
  heroBoxHighlighting: 'box-it',
  heroLogosOnMobile: 'mobileLogo',
  heroProceedsText: 'heroProceedsText',
  webCountdownEnabled: 'webCountDownTimer',
  webShowCardsRemainingHomePage: 'homePageRemainingCards',
  cookieConsentBanner: 'cookieConsentBanner'
};

// Mapping between theme color names and class names
const themeClassNames = {
  primaryColor: 'bg-primary',
  secondaryColor: 'bg-secondary',
  accentColor: 'bg-accent',
  primaryContrast: 'text-primary',
  secondaryContrast: 'text-secondary',
  accentContrast: 'text-accent'
};

// Mapping between color names and class names
const colorClassNames = {
  heroBackgroundColor: 'video-main',
  heroTextColor: 'heroTextColorClass',
  highlightPricingColor: 'highlightPricePointColorClass',
  heroDrawTitleColor: 'heroDrawTitleColorClass',
  heroProgressiveTitleColor: 'heroProgressiveTitleColorClass',
  heroWeeklyTitleColor: 'heroWeeklyTitleColorClass',
  envelopeNumberColor: 'envelopeNumberColorClass',
  cookieBannerButtonAcceptColor: 'cookieBannerButtonAcceptColorClass',
  cookieBannerButtonDeclineColor: 'cookieBannerButtonDeclineColorClass',
  cookieBannerTextColor: 'cookieBannerTextColorClass',
  cookieBannerBackgroundColor: 'cookieBannerBackgroundColorClass',
};

// Mapping between asset names and class names
const assetClassNames = {
  headerLogo: 'headerLogoClass',
  primaryLeftLogo: 'primaryLeftLogoClass',
  primaryRightLogo: 'primaryRightLogoClass',
  email: 'emailClass',
  heroVideo: 'heroVideoClass',
  heroImage: 'heroImageClass',
  checkoutBackgroundImage: 'checkoutBackgroundImageClass',
  envelopeBackgroundImage: 'envelopeBackgroundImageClass',
  socialOgImage: 'socialOgImageClass',
  step1Logo: 'step1LogoClass',
  step2Logo: 'step2LogoClass',
  step3Logo: 'step3LogoClass',
  step4Logo: 'step4LogoClass',
  step5Logo: 'step5LogoClass'
};

// Mapping between asset names and SVG class names
const assetSvgClassNames = {
  step1Logo: 'step1SvgClass',
  step2Logo: 'step2SvgClass',
  step3Logo: 'step3SvgClass',
  step4Logo: 'step4SvgClass',
  step5Logo: 'step5SvgClass'
};

class App extends React.Component {
  _mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      settings: {},
      localLanguage: {},
      events: [],
      event: { id: 0 },
      pollInterval: false,
      iFrameAssets: null,
      referrerCode: null,
    };
  };

  // Add the event listener when the component mounts - used by next-gen-dash
  messageListener = (event, dashUrl) => {
    // Check the origin of the message
    if (event.origin !== dashUrl) return; // Replace with dash url

    // Ignore messages that don't contain the toggle, theme and color data
    if (!event.data ||
      typeof event.data !== 'object' ||
      !('toggles' in event.data) ||
      !('theme' in event.data) ||
      !('colors' in event.data) ||
      !('assets' in event.data)
    ) return;

    // The data sent from the parent page is in event.data
    const { toggles, theme, colors, assets } = event.data;

    // Store the message data in state to be passed down to child components
    this.setState({ iFrameAssets: assets });

    // Handle each toggle
    for (let toggle in toggleClassNames) {
      if (toggle === 'cookieConsentBanner') {
        this.setState({ cookieBannerVisible: toggles[toggle].checked ? 'show' : 'hidden' });
      } else {
        let element = document.querySelector(`.${toggleClassNames[toggle]}`);
        if (element) {
          if (toggle === 'heroBoxHighlighting') {
            element.style.boxShadow = (toggles[toggle] && toggles[toggle].checked) ? '' : 'none';
          } else {
            element.style.display = (toggles[toggle] && toggles[toggle].checked) ? 'block' : 'none';
          }
        }
      }
    }

    // Handle each theme
    for (let color in themeClassNames) {
      let element = document.querySelector(`.${themeClassNames[color]}`);
      if (element) {
        if (themeClassNames[color].startsWith('bg')) {
          element.style.backgroundColor = theme[color];
        } else {
          element.style.color = theme[color];
        }
      }
    }

    // Handle each color
    for (let color in colorClassNames) {
      // Skip cookie colors
      if (color.includes('cookieBanner')) {
        this.setState({ [color]: colors[color] });
        continue;
      }

      let element = document.querySelector(`.${colorClassNames[color]}`);
      if (element) {
        if (color === 'heroBackgroundColor') {
          element.style.backgroundColor = colors[color];
        } else {
          element.style.color = colors[color];
        }
      }
    }

    // Handle each asset
    for (let asset in assetClassNames) {
      let element = document.querySelector(`.${assetClassNames[asset]}`);
      if (element) {
        // If the asset has been updated, update the src and hide the SVG
        if (assets[asset] !== '') {
          element.src = assets[asset];

          // If the asset name starts with "step", hide the corresponding SVG element
          if (asset.startsWith('step')) {
            let svgElement = document.querySelector(`.${assetSvgClassNames[asset]}`);
            if (svgElement) {
              svgElement.style.display = 'none';
            }
          }
        }
      }
    }
  };

  // So this does all the work here
  // get settings, get events, get localize language and get the event,
  // anything goes wrong you will see spinning squares
  componentDidMount = async () => {
    this._mounted = true;
    if (this.props.location.pathname === "/blocked") {
      return;
    }

    await apiUrl();
    let config = await request.get("api:/web/config", {});
    let settings = await request.get("api:/web/settings", {});
    let events = await request.get("api:/web/event", {});

    const { cookies } = this.props;
    let cookieUserConsent = cookies.get('cookieUserConsent') ? +cookies.get('cookieUserConsent') : 0;
    let cookieConsent = +settings.cookie_consent_default; // this will be default value assigned from settings
    if (cookieUserConsent) {
      cookieUserConsent = 1;
      cookieConsent = cookies.get('cookieConsent') ? +cookies.get('cookieConsent') : 0;
    }

    cookies.set("cookieConsent", cookieConsent, { path: "/" });
    cookies.set("cookieUserConsent", cookieUserConsent, { path: "/" });

    // Add the event listener when the component mounts (listen to iframe messages from next-gen-dash)
    window.addEventListener('message', (event) => this.messageListener(event, config.dash_url));

    const acceptedLocales = ['en', 'fr', 'es']; // accepted Locales
    let navigatorLanguage = navigator?.language ?? "en-US"; // browser default
    if (!acceptedLocales.includes(navigatorLanguage.substring(0,2))) navigatorLanguage = "en-US"; // set default locale
    let browserLocale = localStorage.getItem('browserLocale') ? localStorage.getItem('browserLocale') : navigatorLanguage;

    let localLanguage = await this.getLanguage(browserLocale); // get language strings

    if (config.exception || localLanguage.exception || settings.exception || events.exception) {
      // network error we do not want to render the page...
      // This will show the spinning squares but we may want an error page component at some point
      return;
    }
    let event = await this.getEvent(events);
    if (event.exception) {
      // network error we do not want to render the page...
      // This will show the spinning squares but we may want an error page component at some point
      return;
    }
    document.title = `${settings.client_name} E-Commerce`;

    let cookieBannerVisiblity = cookieUserConsent ? 'hidden' : 'show';
    if (!+settings.cookie_consent_banner) {
      cookieBannerVisiblity = 'hidden';
    }

    this.setState({
      config: config,
      settings: settings,
      locale: browserLocale,
      localLanguage: localLanguage,
      events: events,
      event: event,
      loading: false,
      cookieBannerVisible: cookieBannerVisiblity,
      cookieBannerBackgroundColor: settings.cookie_banner_background_color,
      cookieBannerButtonAcceptColor: settings.cookie_banner_button_accept_color,
      cookieBannerButtonDeclineColor: settings.cookie_banner_button_decline_color,
      cookieBannerTextColor: settings.cookie_banner_text_color,
      referrerCode: null
    }, () => this.pollData());
  };

  componentWillUnmount = () => {
    this._mounted = false;
    clearInterval(this.state.pollInterval);

    // remove the event listener when the component unmounts
    window.removeEventListener('message', this.messageListener);
  };

  getLanguage = async (browserLocale) => {
    let lang = await request.get("api:/web/language", {locale: browserLocale});
    lang.setLocale = browserLocale;
    return lang;
  }

  setLanguage = async (e) => {
    e && e.stopPropagation();
    const userSelectedLocale = e?.target?.value ?? navigator.language;
    localStorage.setItem('browserLocale', userSelectedLocale); // set local storage - only client side

    let lang = await request.get("api:/web/language", {locale: userSelectedLocale}); // fetch new language strings
    if (lang.exception) return;
    lang.setLocale = userSelectedLocale; // append "setLocale"

    this.setState({ localLanguage: lang, locale: userSelectedLocale });
  }

  getEvent = async (events) => {
    const { cookies } = this.props;
    const qs = queryString.parse(this.props.location.search);
    let eventId = 0;
    if (events && events.length > 0) {
      eventId = events[0].id;
    }
    if (cookies.get('eventId')) {
      eventId = cookies.get('eventId');
    }
    if (qs.eventId && qs.eventId > 0) {
      eventId = qs.eventId;
    }
    if (+eventId > 0) {
      let event = await request.get(`api:/web/event/${eventId}`, {});

      // If this is an addon event, redirect to parent
      if (event.parent_id !== 0) {
        window.location.href = "/?eventId=" + event.parent_id;
        return;
      }

      //handle error
      if (event.exception) {
        // my opion, if an exception occurs and we handle it in the else statement below the user is booted to the EventSelection Screen
        // if they are continuing to have problems clicking on the event selection screen does nothing and can lead to user frustration
        // if instead we return here then the user will see the spinning squares instead, likely user eventually hard reload the page,
        // understanding that they are likely having network issues.
        // We can possibly explore if creating an error page saying it looks like they are having Internet Issues click here to reload page
        return event;
      }
      if (event.id && event.id > 0) {
        cookies.set('eventId', event.id);
        return event;
      } else {
        return { id: 0 };
      }
    } else {
      return { id: 0 };
    }
  };
  leaveEvent = (prevPath) => {
    const { cookies, history } = this.props;
    cookies.remove('eventId');
    cookies.set('previousPath', prevPath);
    this.setState({
      event: { id: 0 }
    }, history.push("/?eventId=0"));
  }

  pollData = () => {
    let self = this;
    const { event } = this.state;

    if (event.id > 0) {
      return request
      .get(`api:/web/event/${this.state.event.id}`, {})
      .then(resp => {
        if (!this._mounted) return false;

        // event can be an error object
        if (resp.exception) {
          // network error we do not want to render the page...
          //we do not want to to update the event with an error object but we also want
          //the polling to continue so set is_active to true on the error object and return the error object
          resp.is_active = true;
          return resp;
        }

        self.setState({
          event: resp
        });
        return resp;
      })
      .then(event => {
        self.handlePoll(event);
        return true;
      });
    }
  };

  handlePoll = resp => {
    const qs = queryString.parse(this.props.location.search);
    if (!this._mounted || qs.hash && qs.orderId) return false; // exit polling if user on Thank-you or Print-tickets page

    if (!this.state.pollInterval) {
        let poll = setInterval(this.pollData, 15000);
        this.setState({
            pollInterval: poll
        });
    }
  };

  setCookieBannerVisible = (value) => {
    const { cookies } = this.props;
    cookies.set("cookieUserConsent", 1, { path: "/" });
    this.setState({ cookieBannerVisible: value });
  }

  render() {
    if (this.state.loading && this.props.location.pathname != "/blocked") {
      return "";
    }

    if (this.state.loading && this.props.location.pathname === "/blocked") {
      return <BlockedPage />;
    }

    const { cookies, history, location } = this.props;
    const { event, events, localLanguage, settings, config } = this.state;

    return (
      <AppContext.Provider value={this.state}>
        <div className="main font-sans">

          {settings &&
            <Helmet>
              <link href={settings.web_theme_url} rel="stylesheet"></link>
              {settings.helmet_code_snippet && parse(settings.helmet_code_snippet)}
              {settings.helmet_google_tag_manager && parse(settings.helmet_google_tag_manager)}
              {settings.helmet_google_analytics && parse(settings.helmet_google_analytics)}
              {settings.helmet_fb_pixel_script && <script>{settings.helmet_fb_pixel_script}</script>}
              {settings.helmet_fb_pixel_noscript && <noscript>{settings.helmet_fb_pixel_noscript}</noscript>}
              {settings.open_graph_tags && parse(settings.open_graph_tags)}
            </Helmet>
          }

          {config && config.webPayments === 'shift4' &&
            <Helmet>
              <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.0/jquery.min.js"></script>
              <script type='text/javascript' src={`${config.shift4_i4m_url}/js/jquery.i4goTrueToken.js`}></script>
              <link rel="stylesheet" href={`${config.shift4_i4m_url}/css/wallets.css`} />
              <script type="text/javascript" src={`${config.shift4_i4m_url}/js/wallets.js`}></script>
              <script type="text/javascript" src="https://pay.google.com/gp/p/js/pay.js"></script>
            </Helmet>
          }

          {(history.location.pathname.indexOf("/print-tickets") === -1 && history.location.pathname.indexOf("/print-codes") === -1) && (            
            <header className="bg-primary text-accent">
              <HeaderNav handleLanguageSwitch={this.setLanguage} locale={localLanguage.setLocale} />
              {event.id > 0 && events.length > 0 && (events.length !== 1 || events[0].id !== event.id) && (
                <button className="flex justify-center w-full bg-accent hover:bg-accent-dark text-accent-contrast font-bold py-4 px-3" onClick={() => this.leaveEvent(location.pathname)}>
                  <span className="self-center">{localLanguage.general_active_events.replace('{events.length}', events.length)}</span>
                  <svg className="self-center w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" /></svg>
                </button>
              )}
            </header>
          )}
          <main style={{ minHeight: '60vh' }}>
            <Switch>
              <MyRoute
                path="/"
                cookies={cookies}
                exact
                component={Home}
                settings={settings}
              />
              <MyRoute
                path="/contact"
                cookies={cookies}
                exact
                component={Contact}
                settings={settings}
              />
              <MyRoute
                path="/winners"
                cookies={cookies}
                exact
                component={Winners}
                settings={settings}
              />
              <MyRoute
                path="/about"
                title={localLanguage.aboutus_link_title}
                page="aboutus"
                cookies={cookies}
                exact
                component={ContentManaged}
                settings={settings}
              />
              <MyRoute
                path="/claim-forms"
                title={localLanguage.claimforms_link_title}
                page="claimforms"
                cookies={cookies}
                exact
                component={ContentManaged}
                settings={settings}
              />
              <MyRoute
                path="/faq"
                title={localLanguage.faq_link_title}
                page="faq"
                cookies={cookies}
                exact
                component={ContentManaged}
                settings={settings}
              />
              <MyRoute
                path="/privacy"
                title={localLanguage.privacy_link_title}
                page="privacy"
                cookies={cookies}
                exact
                component={ContentManaged}
                settings={settings}
              />
              <MyRoute
                path="/proceeds"
                title={localLanguage.proceeds_link_title}
                page="proceeds"
                cookies={cookies}
                exact
                component={ContentManaged}
                settings={settings}
              />
              <MyRoute
                path="/rules"
                title={localLanguage.rules_link_title}
                page="rules"
                cookies={cookies}
                exact
                component={ContentManaged}
                settings={settings}
              />
              <MyRoute
                path="/terms"
                title={localLanguage.terms_link_title}
                page="terms"
                cookies={cookies}
                exact
                component={ContentManaged}
                settings={settings}
              />
              <MyRoute
                path="/print-tickets"
                cookies={cookies}
                exact
                component={PrintSuper}
                settings={settings}
              />
              <MyRoute
                path="/print-codes"
                cookies={cookies}
                exact
                component={PrintDiscountCodeSuper}
                settings={settings}
              />
              {config.allowFreeTicket ?
                (<MyRoute
                  path="/free-tickets/:page"
                  cookies={cookies}
                  exact
                  component={CheckoutFreeTicket}
                  settings={settings}
                />) : (<MyRoute
                  path="/free-tickets/:page"
                  cookies={cookies}
                  exact
                  component={Checkout}
                  settings={settings}
                />)
              }
              <MyRoute
                path="/how-it-works"
                title={localLanguage.howitworks_link_title}
                page="howitworks"
                cookies={cookies}
                exact
                component={ContentManaged}
                settings={settings}
              />
              <MyRoute
                path="/location-troubleshooting"
                title={localLanguage.locationtroubleshooting_link_title}
                page="locationtroubleshooting"
                cookies={cookies}
                exact
                component={ContentManaged}
                settings={settings}
              />
              <MyRoute
                path="/:page"
                cookies={cookies}
                exact
                component={Checkout}
                settings={settings}
              />

            </Switch>
          </main>
          {(history.location.pathname.indexOf("/print-tickets") === -1  && history.location.pathname.indexOf("/print-codes") === -1) && (
            <footer className="bg-primary text-accent">
              <PaymentTypes
                payment_mastercard={settings.payment_mastercard}
                payment_mastercard_debit={settings.payment_mastercard_debit}
                payment_discover={settings.payment_discover}
                payment_amex={settings.payment_amex}
                payment_visa={settings.payment_visa}
                payment_visa_debit={settings.payment_visa_debit}
                payment_interac={settings.payment_interac}
              />
              <FooterBody
                socialFollowFacebookUrl={settings.social_follow_facebook_url}
                socialFollowTwitterkUrl={settings.social_follow_twitterk_url}
                socialFollowYoutubeUrl={settings.social_follow_youtube_url}
                socialInstagramUrl={settings.social_instagram_url}
              />
              <FooterLinks />
              <FooterPoweredBy />
            </footer>
          )}
          <CookieConsent
            enableDeclineButton
            cookieValue={1}
            declineCookieValue={0}
            declineButtonText={localLanguage.cookies_banner_deny}
            location="bottom"
            buttonText={localLanguage.accept}
            cookieName="cookieConsent"
            style={{ background: this.state.cookieBannerBackgroundColor }}
            buttonStyle={{background: this.state.cookieBannerButtonAcceptColor, color: this.state.cookieBannerTextColor, fontSize: "13px"}}
            declineButtonStyle={{ background: this.state.cookieBannerButtonDeclineColor, color: this.state.cookieBannerTextColor, fontSize: "13px" }} // 4e503b
            expires={150}
            visible={this.state.cookieBannerVisible}
            onAccept={() => this.setCookieBannerVisible('hidden')}
            onDecline={() => this.setCookieBannerVisible('hidden')}
          >
            <span style={{color: this.state.cookieBannerTextColor}}>{localLanguage.cookie_consent_banner_text}</span>
          </CookieConsent>
        </div>
      </AppContext.Provider>
    );
  }
}

export default withCookies(withRouter(App));
