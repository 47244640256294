import React, { Component } from "react";
import { Link } from "react-router-dom";
import { formatCurrency } from "../utils/constants";
import { AppContext } from "../utils/context";
import classnames from 'classnames';

const WinningTicket = (draw,
    title,
    showCard,
    show_weekly_jackpot,
    general_win_ticket,
    general_card,
    general_in_envelope,
    general_see_rules) => {
    if (draw.number !== null && draw.draw_at !== null) {
        return (
        <div className="block px-6">
            <div className={draw.winning_card ? "" : ""}>
            {+show_weekly_jackpot === 1 &&
                title.split('\n').map((item, key) => {
                return <span key={key} className="text-base">{item.replace(/ /g, "\u00a0").toUpperCase()}<br /></span>
                })
            }
            <div className="text-base mb-2">{general_win_ticket}:</div>
            <div className="font-bold text-2xl mb-2">#{draw.number}</div>
            {showCard && draw.card ? (
                <div>
                <span className="inline md:hidden font-bold">{general_card}: </span>
                {draw.card.suit === "D" ? (<span style={{ "color": "red" }}>&diams;</span>) : ""}
                {draw.card.suit === "H" ? (<span style={{ "color": "red" }}>&hearts;</span>) : ""}
                {draw.card.suit === "S" ? (<span style={{ "color": "black" }}>&spades;</span>) : ""}
                {draw.card.suit === "C" ? (<span style={{ "color": "black" }}>&clubs;</span>) : ""}
                {" " + draw.card.rank}

                {` ${general_in_envelope}${draw.card.envelope}`}
                </div>
            ) : (
                <div className="text-xs italic mt-1">
                    <Link to='/rules'>
                        {general_see_rules.replace('{title}', title)}
                    </Link>
                </div>
                )}
            </div>
        </div>
        )
    }
    return '';
}

class ChaseTheAce extends Component {
  render() {
    const { title, date, jackpot, prize, draws, addons, custom_draw_text } = this.props.event;
    const { localLanguage, settings, config } = this.context;
    const heroDrawTitle = classnames('font-semibold', 'mb-2', 'heroDrawTitleColorClass', settings.font_size_raffle_title ); //text-3xl
    const heroProgressiveJackpotTitle = classnames('mt-8', settings.font_size_progressive_title ); //text-2xl
    const heroProgressiveJackpotTotalTitle = classnames('font-bold', settings.font_size_progressive_jackpot_title ); //text-5xl
    const heroWeeklyPrizeTitle = classnames('mt-8', settings.font_size_weekly_title ); //text-2xl
    const heroWeeklyPrizeTotalTitle = classnames('font-bold ', settings.font_size_weekly_prize_title ); //text-5xl
    const heroAddonLabelClasses = classnames('heroAreaAddonLabel font-light', 'my-2', settings.font_size_prize_label ); //text-xl
    const heroAddonPrizeClasses = classnames('heroAreaAddonPrize font-bold ', 'text-xl' ); //text-xl

    return (
      <div >
        <div className={heroDrawTitle} style={{ color: settings.hero_draw_title_color }}>{title}</div>

        <div className="jackpot ">
          <div className="heroProgressiveTitleColorClass" style={{ color: settings.hero_progressive_title_color }}>
            <div className={heroProgressiveJackpotTitle}>
              {localLanguage.game_3_jackpot_title.toUpperCase()}
            </div>
            <div className={heroProgressiveJackpotTotalTitle}>
              {formatCurrency(jackpot)}
            </div>
            {draws[0].winning_card && draws[1] && (
              <div className="mb-8 mt-8">
                {WinningTicket(draws[0],
                    localLanguage.game_3_final_draw,
                    false,
                    settings.show_weekly_jackpot,
                    localLanguage.general_win_ticket,
                    localLanguage.general_card,
                    localLanguage.general_in_envelope,
                    localLanguage.general_see_rules
                )}
              </div>
            )}
          </div>
          <div className="heroWeeklyTitleColorClass" style={{ color: settings.hero_weekly_title_color }} >
            <div className={heroWeeklyPrizeTitle}>
              {localLanguage.game_3_prize_title.toUpperCase()}
            </div>
            {+settings.show_weekly_jackpot === 1 &&
              <div className={heroWeeklyPrizeTotalTitle}>
                {formatCurrency(prize)}
              </div>
            }

            {draws.length > 1 && draws[1].number !== null && draws[1].is_secondary !== 1 && (
              <div className="mb-8">
                {WinningTicket(draws[1],
                    localLanguage.game_3_prize_title,
                    true,
                    settings.show_weekly_jackpot,
                    localLanguage.general_win_ticket,
                    localLanguage.general_card,
                    localLanguage.general_in_envelope,
                    localLanguage.general_see_rules
                )}
              </div>)
            }
            {!draws[0].winning_card && draws[0].number !== null && (
              <div className="mb-8 mt-8">
                {WinningTicket(draws[0],
                    localLanguage.game_3_prize_title,
                    true,
                    settings.show_weekly_jackpot,
                    localLanguage.general_win_ticket,
                    localLanguage.general_card,
                    localLanguage.general_in_envelope,
                    localLanguage.general_see_rules
                )}
              </div>)}
            {draws[0].winning_card && !draws[1] && (
              <div className="mb-8 mt-8">
                {WinningTicket(draws[0],
                    localLanguage.game_3_jackpot_title + '\n& ' + localLanguage.game_3_prize_title,
                    true,
                    settings.show_weekly_jackpot,
                    localLanguage.general_win_ticket,
                    localLanguage.general_card,
                    localLanguage.general_in_envelope,
                    localLanguage.general_see_rules
                )}
              </div>
            )}
          </div>
        </div>

        {config.option_allow_addons && +settings.hero_display_addon_prize > 0 && (addons?.length > 0 ?? false) && ( // List Add-on prizes
            <div className={heroAddonLabelClasses}>
                <span className="heroAddonPrizePrefix">
                    {`${localLanguage.general_hero_addon_raffle_prize.toUpperCase()}: `}
                </span>
                {addons.map((addon) => <div className={heroAddonPrizeClasses} key={addon.id} style={{ marginTop: '1em' }}>
                    <div style={{ fontSize:18, fontWeight:'normal' }}>{addon.title}</div>

                    {+settings.hero_display_jackpot > 0 && +settings.hero_display_prize < 1 &&
                        <>
                            {addon.game_id === 2 &&
                                `${localLanguage.general_prize}: ${addon.prize}`}
                            {addon.game_id !== 2 &&
                                `${addon.game_id === 1 ?
                                    localLanguage.general_jackpot : localLanguage.general_prize}:
                                ${addon.game_id === 1 ?
                                    formatCurrency(addon.jackpot) : formatCurrency(addon.prize)}`
                            }
                        </>
                    }

                    {+settings.hero_display_prize > 0 && +settings.hero_display_jackpot < 1 &&
                        <>
                            {addon.game_id === 2 &&
                                `${localLanguage.general_prize}: ${addon.prize}`}
                            {addon.game_id !== 2 &&
                                `${localLanguage.general_prize}:
                                ${formatCurrency(addon.prize)}`
                            }
                        </>
                    }

                    {+settings.hero_display_jackpot > 0 && +settings.hero_display_prize > 0 &&
                        <>
                            <div>
                                {addon.game_id === 2 &&
                                    `${localLanguage.general_prize}: ${addon.prize}`}
                                {addon.game_id !== 2 &&
                                    `${addon.game_id === 1 ?
                                        localLanguage.general_jackpot : localLanguage.general_prize}:
                                    ${addon.game_id === 1 ?
                                        formatCurrency(addon.jackpot) : formatCurrency(addon.prize)}`
                                }
                            </div>

                            <div>{addon.game_id === 1 &&
                                `${localLanguage.general_prize}: ${formatCurrency(addon.prize)}`}
                            </div>
                        </>
                    }

                </div>)}
            </div>
        )}

        <div className="font-medium text-2x1 mt-8 heroDrawTitleColorClass" style={{ color: settings.hero_draw_title_color }}>
          {`${localLanguage.general_draw_date}: ${custom_draw_text === undefined || custom_draw_text === "" ? date : custom_draw_text}`}
        </div>

        {draws[0].number !== null && (<Link to="/winners"> {localLanguage.general_see_all_winners} </Link>)}
      </div>
    );
  }
}
ChaseTheAce.contextType = AppContext;

export default ChaseTheAce;
