import React, { Component } from "react";
import { Link } from "react-router-dom";
import { formatCurrency } from "../utils/constants";
import classnames from 'classnames';

import { AppContext } from "./../utils/context";

class SplitThePot extends Component {
  render() {
    const { title, date, jackpot, prize, draws, addons, custom_draw_text } = this.props.event;
    const { settings } = this.props;
    const { localLanguage, config } = this.context;

    const heroDrawTitleClasses = classnames('font-semibold', 'mb-2',  settings.font_size_raffle_title ); //text-3xl
    const heroProgressiveJackpotClasses = classnames('heroAreaJackpot font-bold', settings.font_size_progressive_jackpot_title ); //text-5xl
    const heroWeeklyPrizeTotalTitle = classnames('font-bold ', settings.font_size_weekly_prize_title ); //text-5xl
    const heroWeeklyPrizeOnlyClasses = classnames('font-bold ', 'mt-2', settings.font_size_weekly_prize_title ); //text-5xl
    const heroDrawDateLabelClasses = classnames('heroAreaDrawLabel font-light', settings.font_size_draw_date_label ); //text-xs
    const heroDrawDateClasses = classnames('heroAreaDrawDate font-light', settings.font_size_draw_date_value ); //text-base
    const heroJackpotBottomTextClasses = classnames('font-light', 'mt-4', 'mb-4', settings.font_size_jackpot_bottom_text ); //text-xl
    const heroPrizeLabelClasses = classnames('heroAreaPrizeLabel font-light', 'my-2', settings.font_size_prize_label ); //text-xl
    const heroPrizeOnlyLabelClasses = classnames('heroAreaPrizeLabel font-light', 'mt-6', settings.font_size_prize_label ); //text-xl
    const heroJackpotLabelClasses = classnames('heroAreaJackpotLabel font-light', 'my-2', settings.font_size_prize_label ); //text-xl
    const heroAddonLabelClasses = classnames('heroAreaAddonLabel font-light', 'my-2', settings.font_size_prize_label ); //text-xl
    const heroAddonPrizeClasses = classnames('heroAreaAddonPrize font-bold ', 'text-xl' ); //text-xl
    return (
      <div>
        <div className={heroDrawTitleClasses}>{title}</div>
        <div className={heroDrawDateLabelClasses}>{localLanguage.general_draw_date}</div>
        <div className={heroDrawDateClasses}>
            {custom_draw_text === undefined || custom_draw_text === "" ? date : custom_draw_text}
        </div>
        {+settings.hero_display_jackpot > 0 && (
          <div className="jackpot">
            <div className={heroJackpotLabelClasses}>
                <span>
                    {`${localLanguage.general_current_jackpot}: `}
                </span>
                <div className={heroProgressiveJackpotClasses}>
                    {formatCurrency(jackpot)}
                </div>
            </div>
            {localLanguage.general_winner_takes_home &&
                <div className={heroJackpotBottomTextClasses}>
                    {localLanguage.general_winner_takes_home}
                </div>
            }
            {+settings.hero_display_prize > 0 && (
              <div className={heroPrizeLabelClasses}>
                <span className="heroEstimatedPrize">
                    {`${localLanguage.general_est_prize}: `}
                </span>
                <div className={heroWeeklyPrizeTotalTitle}>
                  {formatCurrency(prize)}
                </div>
              </div>
            )}
          </div>
        )}
        {+settings.hero_display_jackpot === 0 && +settings.hero_display_prize > 0 && (
          <div className="jackpot">
            <div className={heroPrizeOnlyLabelClasses}>
                {`${localLanguage.general_est_prize}: `}
            </div>
            <div className={heroWeeklyPrizeOnlyClasses}>
              {formatCurrency(prize)}
            </div>
          </div>
        )}

        {config.option_allow_addons && +settings.hero_display_addon_prize > 0 && (addons?.length > 0 ?? false) && ( // List Add-on prizes
            <div className={heroAddonLabelClasses}>
                <div className="heroAddonPrizePrefix">
                    {`${localLanguage.general_hero_addon_raffle_prize.toUpperCase()}: `}
                </div>
                {addons.map((addon) => <div className={heroAddonPrizeClasses} key={addon.id} style={{ marginTop: '1em' }}>
                    <div style={{ fontSize:18, fontWeight:'normal' }}>{addon.title}</div>

                    {+settings.hero_display_jackpot > 0 && +settings.hero_display_prize < 1 &&
                        <>
                            {addon.game_id === 2 &&
                                `${localLanguage.general_prize}: ${addon.prize}`}
                            {addon.game_id !== 2 &&
                                `${addon.game_id === 1 ?
                                    localLanguage.general_jackpot : localLanguage.general_prize}:
                                ${addon.game_id === 1 ?
                                    formatCurrency(addon.jackpot) : formatCurrency(addon.prize)}`
                            }
                        </>
                    }

                    {+settings.hero_display_prize > 0 && +settings.hero_display_jackpot < 1 &&
                        <>
                            {addon.game_id === 2 &&
                                `${localLanguage.general_prize}: ${addon.prize}`}
                            {addon.game_id !== 2 &&
                                `${localLanguage.general_prize}:
                                ${formatCurrency(addon.prize)}`
                            }
                        </>
                    }

                    {+settings.hero_display_jackpot > 0 && +settings.hero_display_prize > 0 &&
                        <>
                            <div>
                                {addon.game_id === 2 &&
                                    `${localLanguage.general_prize}: ${addon.prize}`}
                                {addon.game_id !== 2 &&
                                    `${addon.game_id === 1 ?
                                        localLanguage.general_jackpot : localLanguage.general_prize}:
                                    ${addon.game_id === 1 ?
                                        formatCurrency(addon.jackpot) : formatCurrency(addon.prize)}`
                                }
                            </div>

                            <div>{addon.game_id === 1 &&
                                `${localLanguage.general_prize}: ${formatCurrency(addon.prize)}`}
                            </div>
                        </>
                    }

                </div>)}
            </div>
        )}


        {draws[0].number !== null && (
          <div className="block py-3 px-6">
            <div className={draws[0].winning_card ? "text-primary" : ""}>
              <div className="text-base mb-2">{localLanguage.general_win_ticket}:</div>
              <div className="font-bold text-2xl mb-2">#{draws[0].number}</div>
              <Link
                to={`/winners`}
                className="inline-block font-bold rounded"
              >
                {`${localLanguage.general_see_all_winners} >`}
                </Link>
            </div>
          </div>
          )}
      </div>
    );
  }
}
SplitThePot.contextType = AppContext;

export default SplitThePot;
